import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTimeISO: { input: string; output: string };
  JSON: { input: unknown; output: unknown };
  JSONObject: { input: unknown; output: unknown };
};

export type BiogenicBreakdown = {
  __typename?: 'BiogenicBreakdown';
  co2: EmissionNumber;
};

export enum CalculatorFreightFuelTypeEnum {
  Cng = 'CNG',
  Diesel = 'DIESEL',
  Electricity = 'ELECTRICITY',
  Lng = 'LNG',
  Lpg = 'LPG',
  Other = 'OTHER',
  Petrol = 'PETROL',
}

export type CalculatorInput = {
  __typename?: 'CalculatorInput';
  id: Scalars['String']['output'];
  input: ComputeFreightEmissions;
};

export type CalculatorUsage = {
  __typename?: 'CalculatorUsage';
  status: UsageStatus;
};

export type CarbonplanAssessment = {
  __typename?: 'CarbonplanAssessment';
  created_date: Scalars['DateTimeISO']['output'];
  data_stringify?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export type Client = {
  __typename?: 'Client';
  address_1?: Maybe<Scalars['String']['output']>;
  address_2?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<S3Object>;
  billing_email_address?: Maybe<Scalars['String']['output']>;
  branding?: Maybe<ClientBranding>;
  city?: Maybe<Scalars['String']['output']>;
  client_function?: Maybe<ClientFunctionEnum>;
  config: ClientConfig;
  country?: Maybe<Country>;
  domains: Array<ClientDomain>;
  id: Scalars['ID']['output'];
  legal_name?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<S3Object>;
  offsetting_commission_percentage: Scalars['Float']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
  shipment_reference_number_metadata_key: Scalars['String']['output'];
  tax_id_type?: Maybe<TaxIdTypeEnum>;
  tax_id_value?: Maybe<Scalars['String']['output']>;
  users: Array<ClientUser>;
  website?: Maybe<Scalars['String']['output']>;
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type ClientBranding = {
  __typename?: 'ClientBranding';
  accentColour: Scalars['String']['output'];
  brandColour: Scalars['String']['output'];
  /** @deprecated No longer used */
  linkTextColour?: Maybe<Scalars['String']['output']>;
};

export type ClientConfig = {
  __typename?: 'ClientConfig';
  auto_transshipment: Scalars['Boolean']['output'];
  client: Client;
  created_date: Scalars['DateTimeISO']['output'];
  emission_sharing_visibility: EmissionVisibilityEnum;
  ferry_load_characteristics: FerryLoadCharacteristicsEnum;
  id: Scalars['ID']['output'];
  road_routing_ferry_preference: RoadRoutingFerryPreferenceEnum;
  sea_routing_restricted_areas: Array<SeaRoutingAreaEnum>;
  updated_date: Scalars['DateTimeISO']['output'];
};

export type ClientDomain = {
  __typename?: 'ClientDomain';
  client: Client;
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  team_access: TeamAccessEnum;
};

export enum ClientFunctionEnum {
  Carrier = 'CARRIER',
  FreightForwarder = 'FREIGHT_FORWARDER',
  IndependentSoftwareVendor = 'INDEPENDENT_SOFTWARE_VENDOR',
  Lsp = 'LSP',
  Other = 'OTHER',
  Shipper = 'SHIPPER',
}

export type ClientRole = {
  __typename?: 'ClientRole';
  id: Scalars['ID']['output'];
  name: ClientRoleEnum;
};

export enum ClientRoleEnum {
  Admin = 'ADMIN',
  Developer = 'DEVELOPER',
  Operations = 'OPERATIONS',
  Owner = 'OWNER',
  Viewer = 'VIEWER',
}

export type ClientUser = {
  __typename?: 'ClientUser';
  account_state: ClientUserAccountStateEnum;
  avatar?: Maybe<S3Object>;
  created_date: Scalars['DateTimeISO']['output'];
  deleted_date?: Maybe<Scalars['DateTimeISO']['output']>;
  email: Scalars['String']['output'];
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  role: ClientRole;
  source: ClientUserSourceEnum;
  updated_date: Scalars['DateTimeISO']['output'];
};

export enum ClientUserAccountStateEnum {
  Authorized = 'AUTHORIZED',
  CreateProfile = 'CREATE_PROFILE',
  Invited = 'INVITED',
  Provisioned = 'PROVISIONED',
  Registered = 'REGISTERED',
  Unauthorized = 'UNAUTHORIZED',
}

export enum ClientUserSourceEnum {
  DataCollectionRequest = 'DATA_COLLECTION_REQUEST',
  GoogleSignUp = 'GOOGLE_SIGN_UP',
  Invited = 'INVITED',
  SelfSignUp = 'SELF_SIGN_UP',
}

export type ComputeFreightEmissions =
  | ComputeFreightEmissionsWithDistanceAndWeightAndVehicle
  | ComputeFreightEmissionsWithLocationsAndWeightAndVehicle;

export type ComputeFreightEmissionsWithDistanceAndWeightAndVehicle = {
  __typename?: 'ComputeFreightEmissionsWithDistanceAndWeightAndVehicle';
  config?: Maybe<FreightShipmentCalculationConfig>;
  containers?: Maybe<Array<FreightContainer>>;
  date?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  movements: Array<MovementWithDistance>;
  track?: Maybe<Scalars['Boolean']['output']>;
  weight: Weight;
};

export type ComputeFreightEmissionsWithLocationsAndWeightAndVehicle = {
  __typename?: 'ComputeFreightEmissionsWithLocationsAndWeightAndVehicle';
  config?: Maybe<FreightShipmentCalculationConfig>;
  containers?: Maybe<Array<FreightContainer>>;
  date?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  movements: Array<MovementWithLocation>;
  track?: Maybe<Scalars['Boolean']['output']>;
  weight: Weight;
};

export type Configuration = {
  __typename?: 'Configuration';
  emission_sharing_visibility?: Maybe<EmissionVisibilityEnum>;
  routing?: Maybe<RoutingConfiguration>;
};

export type ContainerWeight = {
  __typename?: 'ContainerWeight';
  unit?: Maybe<ContainerWeightUnit>;
  value: Scalars['Float']['output'];
};

export enum ContainerWeightUnit {
  Kg = 'KG',
  Lbs = 'LBS',
  Tonnes = 'TONNES',
  Tons = 'TONS',
}

export type Country = {
  __typename?: 'Country';
  code: Scalars['String']['output'];
  code_alpha_2: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum CurrencyEnum {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD',
}

export type DataCollection = {
  __typename?: 'DataCollection';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  latest_request_updated_date: Scalars['DateTimeISO']['output'];
  period_end_date: Scalars['DateTimeISO']['output'];
  period_start_date: Scalars['DateTimeISO']['output'];
  public_id: Scalars['String']['output'];
  requests: Array<DataCollectionRequest>;
  updated_date: Scalars['DateTimeISO']['output'];
};

export type DataCollectionRequest = {
  __typename?: 'DataCollectionRequest';
  activities: Array<DataCollectionRequestActivity>;
  created_date: Scalars['DateTimeISO']['output'];
  data_collection: DataCollection;
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  public_id: Scalars['String']['output'];
  recipients: Array<ClientUser>;
  state: DataCollectionRequestStateEnum;
  updated_date: Scalars['DateTimeISO']['output'];
};

export type DataCollectionRequestAcceptedActivity = {
  __typename?: 'DataCollectionRequestAcceptedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestActivity =
  | DataCollectionRequestAcceptedActivity
  | DataCollectionRequestApprovedActivity
  | DataCollectionRequestCancelledActivity
  | DataCollectionRequestDataStagedActivity
  | DataCollectionRequestDeclinedActivity
  | DataCollectionRequestDeliveryFailedActivity
  | DataCollectionRequestRejectedActivity
  | DataCollectionRequestRemindedActivity
  | DataCollectionRequestRequestFailedActivity
  | DataCollectionRequestRequestedActivity
  | DataCollectionRequestSubmittedActivity
  | DataCollectionRequestViewedActivity;

export type DataCollectionRequestApprovedActivity = {
  __typename?: 'DataCollectionRequestApprovedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestCancelledActivity = {
  __typename?: 'DataCollectionRequestCancelledActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestDataStagedActivity = {
  __typename?: 'DataCollectionRequestDataStagedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  emissions_aggregations?: Maybe<EmissionsAggregations>;
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestDeclinedActivity = {
  __typename?: 'DataCollectionRequestDeclinedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestDeliveryFailedActivity = {
  __typename?: 'DataCollectionRequestDeliveryFailedActivity';
  client?: Maybe<Client>;
  client_user?: Maybe<ClientUser>;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export enum DataCollectionRequestEventEnum {
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  DataStaged = 'DATA_STAGED',
  Declined = 'DECLINED',
  DeliveryFailed = 'DELIVERY_FAILED',
  Rejected = 'REJECTED',
  Reminded = 'REMINDED',
  Requested = 'REQUESTED',
  RequestFailed = 'REQUEST_FAILED',
  Submitted = 'SUBMITTED',
  Viewed = 'VIEWED',
}

export type DataCollectionRequestPublic = {
  __typename?: 'DataCollectionRequestPublic';
  data_collection_request: DataCollectionRequest;
  email: Scalars['String']['output'];
  is_authenticated: Scalars['Boolean']['output'];
};

export type DataCollectionRequestRejectedActivity = {
  __typename?: 'DataCollectionRequestRejectedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestRemindedActivity = {
  __typename?: 'DataCollectionRequestRemindedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestRequestFailedActivity = {
  __typename?: 'DataCollectionRequestRequestFailedActivity';
  client?: Maybe<Client>;
  client_user?: Maybe<ClientUser>;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestRequestedActivity = {
  __typename?: 'DataCollectionRequestRequestedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export enum DataCollectionRequestStateEnum {
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  Done = 'DONE',
  Initial = 'INITIAL',
  InProgress = 'IN_PROGRESS',
  PendingReview = 'PENDING_REVIEW',
  Requested = 'REQUESTED',
}

export type DataCollectionRequestSubmittedActivity = {
  __typename?: 'DataCollectionRequestSubmittedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  emissions_aggregations?: Maybe<EmissionsAggregations>;
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestViewedActivity = {
  __typename?: 'DataCollectionRequestViewedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type Emission = {
  __typename?: 'Emission';
  activity_normalized?: Maybe<Scalars['Float']['output']>;
  activity_normalized_unit?: Maybe<EmissionFactorUnitEnum>;
  client: Client;
  client_user?: Maybe<ClientUser>;
  co2e: Scalars['Float']['output'];
  context_stringify?: Maybe<Scalars['String']['output']>;
  created_date: Scalars['DateTimeISO']['output'];
  date: Scalars['DateTimeISO']['output'];
  details: Array<EmissionDetail>;
  distance_km?: Maybe<Scalars['Float']['output']>;
  emission_children: Array<Emission>;
  flights?: Maybe<EmissionFlights>;
  freight?: Maybe<EmissionFreight>;
  freight_aggregated?: Maybe<EmissionFreightAggregated>;
  from_display?: Maybe<Scalars['String']['output']>;
  has_parent: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  import_id?: Maybe<Scalars['String']['output']>;
  intensity_normalized?: Maybe<Scalars['Float']['output']>;
  issues?: Maybe<Array<EmissionIssue>>;
  last_mile?: Maybe<EmissionLastMile>;
  meta_data?: Maybe<Array<Metadata>>;
  mode: EmissionModeEnum;
  no_of_pax?: Maybe<Scalars['Float']['output']>;
  parent_child_count?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['ID']['output']>;
  parent_public_id?: Maybe<Scalars['String']['output']>;
  parent_quality?: Maybe<EmissionParentQuality>;
  parent_share_token?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  quality?: Maybe<EmissionQuality>;
  ride_hailing?: Maybe<EmissionRideHailing>;
  sequence_no?: Maybe<Scalars['Int']['output']>;
  share_token: Scalars['String']['output'];
  shipment_reference_number_metadata_key: Scalars['String']['output'];
  shipment_reference_number_metadata_value?: Maybe<Scalars['String']['output']>;
  source_type_name: EmissionSourceTypeEnum;
  teu?: Maybe<Scalars['Float']['output']>;
  to_display?: Maybe<Scalars['String']['output']>;
  type: EmissionTypeEnum;
  visibility?: Maybe<EmissionVisibilityEnum>;
  weight_kg?: Maybe<Scalars['Float']['output']>;
};

export type EmissionDetail = {
  __typename?: 'EmissionDetail';
  category?: Maybe<EmissionDetailCategoryEnum>;
  ch4?: Maybe<Scalars['Float']['output']>;
  co2?: Maybe<Scalars['Float']['output']>;
  co2e: Scalars['Float']['output'];
  emission_factor: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  n2o?: Maybe<Scalars['Float']['output']>;
  nmhc?: Maybe<Scalars['Float']['output']>;
  nox?: Maybe<Scalars['Float']['output']>;
  pm?: Maybe<Scalars['Float']['output']>;
  scope?: Maybe<EmissionDetailScopeEnum>;
  so2?: Maybe<Scalars['Float']['output']>;
  subtype?: Maybe<EmissionDetailSubtypeEnum>;
  type?: Maybe<EmissionDetailTypeEnum>;
};

export enum EmissionDetailCategoryEnum {
  BusinessTravel = 'BUSINESS_TRAVEL',
  CapitalGoods = 'CAPITAL_GOODS',
  DownstreamLeasedAssets = 'DOWNSTREAM_LEASED_ASSETS',
  DownstreamTransportAndDistribution = 'DOWNSTREAM_TRANSPORT_AND_DISTRIBUTION',
  EmployeeCommuting = 'EMPLOYEE_COMMUTING',
  EolTreatmentOfSoldProducts = 'EOL_TREATMENT_OF_SOLD_PRODUCTS',
  Franchises = 'FRANCHISES',
  FuelAndEnergyRelated = 'FUEL_AND_ENERGY_RELATED',
  GeneratedWaste = 'GENERATED_WASTE',
  Investments = 'INVESTMENTS',
  ProcessingOfSoldProducts = 'PROCESSING_OF_SOLD_PRODUCTS',
  PurchasedGoodsAndServices = 'PURCHASED_GOODS_AND_SERVICES',
  UpstreamLeasedAssets = 'UPSTREAM_LEASED_ASSETS',
  UpstreamTransportAndDistribution = 'UPSTREAM_TRANSPORT_AND_DISTRIBUTION',
  UseOfSoldProducts = 'USE_OF_SOLD_PRODUCTS',
}

export enum EmissionDetailScopeEnum {
  Scope_1 = 'SCOPE_1',
  Scope_2 = 'SCOPE_2',
  Scope_3 = 'SCOPE_3',
}

export enum EmissionDetailSubtypeEnum {
  Eg = 'EG',
  Etdl = 'ETDL',
  Production = 'PRODUCTION',
}

export enum EmissionDetailTypeEnum {
  Other = 'OTHER',
  Ttw = 'TTW',
  Wtt = 'WTT',
  Wtw = 'WTW',
}

export enum EmissionFactorUnitEnum {
  ContainerMoved = 'CONTAINER_MOVED',
  Km = 'KM',
  PassengerKm = 'PASSENGER_KM',
  TeuKm = 'TEU_KM',
  Tonne = 'TONNE',
  TonneKm = 'TONNE_KM',
}

export type EmissionFlights = {
  __typename?: 'EmissionFlights';
  aircraft?: Maybe<Scalars['String']['output']>;
  airline?: Maybe<Scalars['String']['output']>;
  cabin_class?: Maybe<EmissionFlightsClassEnum>;
  flight_no?: Maybe<Scalars['String']['output']>;
  from_code: Scalars['String']['output'];
  from_coordinates?: Maybe<Geography>;
  from_display?: Maybe<Scalars['String']['output']>;
  no_of_pax: Scalars['Float']['output'];
  to_code: Scalars['String']['output'];
  to_coordinates?: Maybe<Geography>;
  to_display?: Maybe<Scalars['String']['output']>;
};

export enum EmissionFlightsClassEnum {
  Business = 'BUSINESS',
  Economy = 'ECONOMY',
  First = 'FIRST',
  PremiumEconomy = 'PREMIUM_ECONOMY',
}

export type EmissionFreight = {
  __typename?: 'EmissionFreight';
  aircraft_code?: Maybe<Scalars['String']['output']>;
  cargo_type: EmissionFreightCargoTypeEnum;
  carriage: EmissionFreightCarriageEnum;
  carrier_code?: Maybe<Scalars['String']['output']>;
  carrier_name?: Maybe<Scalars['String']['output']>;
  emission_standard?: Maybe<EmissionFreightVehicleEmissionStandardEnum>;
  empty_running?: Maybe<Scalars['Float']['output']>;
  flight_no?: Maybe<Scalars['String']['output']>;
  from_address?: Maybe<Scalars['String']['output']>;
  from_code?: Maybe<Scalars['String']['output']>;
  from_coordinates?: Maybe<Geography>;
  from_country_code?: Maybe<Scalars['String']['output']>;
  from_country_name?: Maybe<Scalars['String']['output']>;
  from_display?: Maybe<Scalars['String']['output']>;
  fuel_consumption?: Maybe<Scalars['Float']['output']>;
  fuel_consumption_unit?: Maybe<EmissionFreightFuelConsumptionUnitNormalisedEnum>;
  fuel_identifier?: Maybe<EmissionFreightFuelIdentifier>;
  fuel_type?: Maybe<EmissionFreightFuelTypeEnum>;
  is_refrigerated: Scalars['Boolean']['output'];
  load_factor?: Maybe<Scalars['Float']['output']>;
  load_type?: Maybe<EmissionFreightLoadTypeEnum>;
  route_details?: Maybe<RouteDetails>;
  to_address?: Maybe<Scalars['String']['output']>;
  to_code?: Maybe<Scalars['String']['output']>;
  to_coordinates?: Maybe<Geography>;
  to_country_code?: Maybe<Scalars['String']['output']>;
  to_display?: Maybe<Scalars['String']['output']>;
  vehicle: EmissionFreightVehicleEnum;
  vehicle_category: EmissionFreightVehicleCategoryEnum;
  vehicle_code?: Maybe<Scalars['String']['output']>;
  vessel_id?: Maybe<Scalars['String']['output']>;
  vessel_name?: Maybe<Scalars['String']['output']>;
};

export type EmissionFreightAggregated = {
  __typename?: 'EmissionFreightAggregated';
  distance_km: Scalars['Float']['output'];
  end_date: Scalars['DateTimeISO']['output'];
  fleet_id?: Maybe<Scalars['String']['output']>;
  fuel_consumption: Scalars['Float']['output'];
  fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
  fuel_type: EmissionFreightAggregatedFuelTypeEnum;
  fuel_used: Scalars['Float']['output'];
  fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
  load_factor: Scalars['Float']['output'];
  no_of_vehicles?: Maybe<Scalars['Float']['output']>;
  start_date: Scalars['DateTimeISO']['output'];
  vehicle: EmissionFreightAggregatedVehicleEnum;
  vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
  vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
  vehicle_id?: Maybe<Scalars['String']['output']>;
  weight_kg: Scalars['Float']['output'];
};

export enum EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum {
  KgPer_100Km = 'KG_PER_100_KM',
  KwhPer_100Km = 'KWH_PER_100_KM',
  LPer_100Km = 'L_PER_100_KM',
}

export enum EmissionFreightAggregatedFuelTypeEnum {
  Cng = 'CNG',
  Diesel = 'DIESEL',
  Electricity = 'ELECTRICITY',
  Lng = 'LNG',
  Lpg = 'LPG',
  Petrol = 'PETROL',
}

export enum EmissionFreightAggregatedFuelUsedUnitNormalisedEnum {
  Kg = 'KG',
  Kwh = 'KWH',
  L = 'L',
}

export enum EmissionFreightAggregatedVehicleCategoryEnum {
  Articulated = 'ARTICULATED',
  AutoCarrier = 'AUTO_CARRIER',
  Default = 'DEFAULT',
  Dray = 'DRAY',
  Expedited = 'EXPEDITED',
  Flatbed = 'FLATBED',
  General = 'GENERAL',
  HeavyBulk = 'HEAVY_BULK',
  Hgv = 'HGV',
  Ltl = 'LTL',
  Mgv = 'MGV',
  Mixed = 'MIXED',
  Moving = 'MOVING',
  Package = 'PACKAGE',
  Refrigerated = 'REFRIGERATED',
  Rigid = 'RIGID',
  Specialized = 'SPECIALIZED',
  Tanker = 'TANKER',
  Tl = 'TL',
  UrbanTruck = 'URBAN_TRUCK',
}

export enum EmissionFreightAggregatedVehicleCodeEnum {
  H = 'H',
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  Ha1 = 'HA1',
  Ha2 = 'HA2',
  Ha3 = 'HA3',
  Ha4 = 'HA4',
  Ha5 = 'HA5',
  Hr1 = 'HR1',
  Hr2 = 'HR2',
  Hr3 = 'HR3',
  Hr4 = 'HR4',
  Hr5 = 'HR5',
  NaHac = 'NA_HAC',
  NaHd = 'NA_HD',
  NaHe = 'NA_HE',
  NaHf = 'NA_HF',
  NaHg = 'NA_HG',
  NaHhb = 'NA_HHB',
  NaHltl = 'NA_HLTL',
  NaHmi = 'NA_HMI',
  NaHmo = 'NA_HMO',
  NaHp = 'NA_HP',
  NaHs = 'NA_HS',
  NaHt = 'NA_HT',
  NaHtl = 'NA_HTL',
  V = 'V',
}

export enum EmissionFreightAggregatedVehicleEnum {
  Truck = 'TRUCK',
  Van = 'VAN',
}

export enum EmissionFreightCargoTypeEnum {
  Average = 'AVERAGE',
  Heavy = 'HEAVY',
  Light = 'LIGHT',
}

export enum EmissionFreightCarriageEnum {
  Main = 'MAIN',
  On = 'ON',
  Pre = 'PRE',
}

export enum EmissionFreightDistanceUnitEnum {
  Km = 'KM',
  Mi = 'MI',
  Nm = 'NM',
}

export enum EmissionFreightFuelConsumptionUnitEnum {
  ImpGalPer_100Mi = 'IMP_GAL_PER_100_MI',
  KgPer_100Km = 'KG_PER_100_KM',
  KwhPer_100Km = 'KWH_PER_100_KM',
  KwhPer_100Mi = 'KWH_PER_100_MI',
  LbsPer_100Mi = 'LBS_PER_100_MI',
  LPer_100Km = 'L_PER_100_KM',
  UsGalPer_100Mi = 'US_GAL_PER_100_MI',
}

export enum EmissionFreightFuelConsumptionUnitNormalisedEnum {
  KgPer_100Km = 'KG_PER_100_KM',
  KwhPer_100Km = 'KWH_PER_100_KM',
  LPer_100Km = 'L_PER_100_KM',
}

export type EmissionFreightFuelIdentifier = {
  __typename?: 'EmissionFreightFuelIdentifier';
  code: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
};

export enum EmissionFreightFuelTypeEnum {
  AviationFuel = 'AVIATION_FUEL',
  Cng = 'CNG',
  Custom = 'CUSTOM',
  Diesel = 'DIESEL',
  Diesel_5Biodiesel = 'DIESEL_5_BIODIESEL',
  Electricity = 'ELECTRICITY',
  Hfo = 'HFO',
  Hybrid = 'HYBRID',
  Lng = 'LNG',
  Lpg = 'LPG',
  Mgo = 'MGO',
  Other = 'OTHER',
  Petrol = 'PETROL',
  PluginHybrid = 'PLUGIN_HYBRID',
}

export enum EmissionFreightLoadTypeEnum {
  Ftl = 'FTL',
  Ltl = 'LTL',
}

export enum EmissionFreightVehicleCategoryEnum {
  Articulated = 'ARTICULATED',
  AutoCarrier = 'AUTO_CARRIER',
  BulkCarrier = 'BULK_CARRIER',
  ContainerShip = 'CONTAINER_SHIP',
  ContainerTerminal = 'CONTAINER_TERMINAL',
  ContainerVessel = 'CONTAINER_VESSEL',
  CoupledConvoy = 'COUPLED_CONVOY',
  Default = 'DEFAULT',
  Dray = 'DRAY',
  Expedited = 'EXPEDITED',
  Flatbed = 'FLATBED',
  Freighter = 'FREIGHTER',
  General = 'GENERAL',
  GeneralCargo = 'GENERAL_CARGO',
  HeavyBulk = 'HEAVY_BULK',
  Hgv = 'HGV',
  LiquidBulkTerminal = 'LIQUID_BULK_TERMINAL',
  Ltl = 'LTL',
  Mgv = 'MGV',
  Mixed = 'MIXED',
  MotorVessel = 'MOTOR_VESSEL',
  Moving = 'MOVING',
  OilTanker = 'OIL_TANKER',
  Package = 'PACKAGE',
  Passenger = 'PASSENGER',
  PushedConvoy = 'PUSHED_CONVOY',
  Refrigerated = 'REFRIGERATED',
  RefrigeratedBulk = 'REFRIGERATED_BULK',
  Rigid = 'RIGID',
  Ropax = 'ROPAX',
  Roro = 'RORO',
  Specialized = 'SPECIALIZED',
  StorageAndTranshipment = 'STORAGE_AND_TRANSHIPMENT',
  Tanker = 'TANKER',
  TankerVessel = 'TANKER_VESSEL',
  Tl = 'TL',
  Transhipment = 'TRANSHIPMENT',
  UrbanTruck = 'URBAN_TRUCK',
  Vehicle = 'VEHICLE',
  Warehouse = 'WAREHOUSE',
}

export enum EmissionFreightVehicleEmissionStandardEnum {
  Euro_1 = 'EURO_1',
  Euro_2 = 'EURO_2',
  Euro_3 = 'EURO_3',
  Euro_4 = 'EURO_4',
  Euro_5 = 'EURO_5',
  Euro_6 = 'EURO_6',
}

export enum EmissionFreightVehicleEnum {
  Aircraft = 'AIRCRAFT',
  Barge = 'BARGE',
  LogisticsSite = 'LOGISTICS_SITE',
  Ship = 'SHIP',
  Train = 'TRAIN',
  Truck = 'TRUCK',
  Van = 'VAN',
}

export enum EmissionFreightWeightUnitEnum {
  Kg = 'KG',
  Lbs = 'LBS',
  Teu = 'TEU',
  Tonnes = 'TONNES',
  Tons = 'TONS',
}

export type EmissionIssue = {
  __typename?: 'EmissionIssue';
  assumed_value?: Maybe<Scalars['String']['output']>;
  input_value?: Maybe<Scalars['String']['output']>;
  param: Scalars['String']['output'];
  recommendations: Array<EmissionIssueRecommendation>;
};

export type EmissionIssueRecommendation = {
  __typename?: 'EmissionIssueRecommendation';
  action: RecommendationAction;
  params: Array<Scalars['String']['output']>;
};

export type EmissionLastMile = {
  __typename?: 'EmissionLastMile';
  from_address?: Maybe<Scalars['String']['output']>;
  from_coordinates?: Maybe<Geography>;
  from_display?: Maybe<Scalars['String']['output']>;
  fuel_type?: Maybe<EmissionLastMileFuelTypeEnum>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  to_address?: Maybe<Scalars['String']['output']>;
  to_coordinates?: Maybe<Geography>;
  to_display?: Maybe<Scalars['String']['output']>;
  vehicle: EmissionLastMileVehicleEnum;
  vehicle_category: EmissionLastMileVehicleCategoryEnum;
};

export enum EmissionLastMileFuelTypeEnum {
  Cng = 'CNG',
  Diesel = 'DIESEL',
  Electricity = 'ELECTRICITY',
  Hybrid = 'HYBRID',
  Lng = 'LNG',
  Lpg = 'LPG',
  Other = 'OTHER',
  Petrol = 'PETROL',
  PluginHybrid = 'PLUGIN_HYBRID',
}

export enum EmissionLastMileVehicleCategoryEnum {
  Default = 'DEFAULT',
  DualPurpose_4X4 = 'DUAL_PURPOSE_4X4',
  Executive = 'EXECUTIVE',
  Large = 'LARGE',
  LowerMedium = 'LOWER_MEDIUM',
  Luxury = 'LUXURY',
  Medium = 'MEDIUM',
  Mini = 'MINI',
  Mpv = 'MPV',
  Small = 'SMALL',
  Sports = 'SPORTS',
  Supermini = 'SUPERMINI',
  UpperMedium = 'UPPER_MEDIUM',
}

export enum EmissionLastMileVehicleEnum {
  Bicycle = 'BICYCLE',
  Car = 'CAR',
  Motorbike = 'MOTORBIKE',
  Van = 'VAN',
}

export enum EmissionModeEnum {
  Air = 'AIR',
  Bicycle = 'BICYCLE',
  Car = 'CAR',
  Flight = 'FLIGHT',
  InlandWaterway = 'INLAND_WATERWAY',
  LogisticsSite = 'LOGISTICS_SITE',
  Motorbike = 'MOTORBIKE',
  Rail = 'RAIL',
  Road = 'ROAD',
  Sea = 'SEA',
  Van = 'VAN',
}

export type EmissionNumber = {
  __typename?: 'EmissionNumber';
  unit: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type EmissionParentQuality = {
  __typename?: 'EmissionParentQuality';
  overall?: Maybe<Scalars['Float']['output']>;
};

export type EmissionQuality = {
  __typename?: 'EmissionQuality';
  emission_intensity_factor?: Maybe<EmissionQualityScoreEnum>;
  origin_destination?: Maybe<EmissionQualityScoreEnum>;
  overall?: Maybe<EmissionQualityScoreEnum>;
  weight?: Maybe<EmissionQualityScoreEnum>;
};

export enum EmissionQualityScoreEnum {
  Excellent = 'EXCELLENT',
  Good = 'GOOD',
  Sufficient = 'SUFFICIENT',
  Unsatisfactory = 'UNSATISFACTORY',
}

export type EmissionRideHailing = {
  __typename?: 'EmissionRideHailing';
  from_address?: Maybe<Scalars['String']['output']>;
  from_coordinates?: Maybe<Geography>;
  from_display?: Maybe<Scalars['String']['output']>;
  fuel_type?: Maybe<EmissionRideHailingFuelTypeEnum>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  no_of_pax?: Maybe<Scalars['Float']['output']>;
  to_address?: Maybe<Scalars['String']['output']>;
  to_coordinates?: Maybe<Geography>;
  to_display?: Maybe<Scalars['String']['output']>;
  vehicle: EmissionRideHailingVehicleEnum;
  vehicle_category: EmissionRideHailingVehicleCategoryEnum;
};

export enum EmissionRideHailingFuelTypeEnum {
  Cng = 'CNG',
  Diesel = 'DIESEL',
  Electricity = 'ELECTRICITY',
  Hybrid = 'HYBRID',
  Lng = 'LNG',
  Lpg = 'LPG',
  Other = 'OTHER',
  Petrol = 'PETROL',
  PluginHybrid = 'PLUGIN_HYBRID',
}

export enum EmissionRideHailingVehicleCategoryEnum {
  Default = 'DEFAULT',
  DualPurpose_4X4 = 'DUAL_PURPOSE_4X4',
  Executive = 'EXECUTIVE',
  Large = 'LARGE',
  LowerMedium = 'LOWER_MEDIUM',
  Luxury = 'LUXURY',
  Medium = 'MEDIUM',
  Mini = 'MINI',
  Mpv = 'MPV',
  Small = 'SMALL',
  Sports = 'SPORTS',
  Supermini = 'SUPERMINI',
  UpperMedium = 'UPPER_MEDIUM',
}

export enum EmissionRideHailingVehicleEnum {
  Bicycle = 'BICYCLE',
  Car = 'CAR',
  Motorbike = 'MOTORBIKE',
  Van = 'VAN',
}

export enum EmissionSourceTypeEnum {
  Calculator = 'CALCULATOR',
  Cargowise = 'CARGOWISE',
  Chainio = 'CHAINIO',
  DataCollection = 'DATA_COLLECTION',
  Import = 'IMPORT',
  PublicApi = 'PUBLIC_API',
  ShopifyWebhook = 'SHOPIFY_WEBHOOK',
}

export enum EmissionTypeEnum {
  Flights = 'FLIGHTS',
  Freight = 'FREIGHT',
  FreightAggregated = 'FREIGHT_AGGREGATED',
  LastMile = 'LAST_MILE',
  RideHailing = 'RIDE_HAILING',
}

export enum EmissionVisibilityEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type EmissionsAggregations = {
  __typename?: 'EmissionsAggregations';
  activity_end_date: Scalars['DateTimeISO']['output'];
  activity_start_date: Scalars['DateTimeISO']['output'];
  emissions_by_lifecycle: Array<EmissionsLifecycleBreakdown>;
  emissions_by_mode: Array<EmissionsModeBreakdown>;
  overall_quality: Scalars['Float']['output'];
  total_activity: Scalars['Float']['output'];
  total_activity_unit: Scalars['String']['output'];
  total_co2e: Scalars['Float']['output'];
  total_intensity: Scalars['Float']['output'];
  total_intensity_unit: Scalars['String']['output'];
  total_shipments: Scalars['Int']['output'];
};

export type EmissionsLifecycleBreakdown = {
  __typename?: 'EmissionsLifecycleBreakdown';
  activity?: Maybe<Scalars['Float']['output']>;
  activity_unit?: Maybe<Scalars['String']['output']>;
  co2e: Scalars['Float']['output'];
  intensity?: Maybe<Scalars['Float']['output']>;
  intensity_unit?: Maybe<Scalars['String']['output']>;
  label: EmissionDetailTypeEnum;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type EmissionsModeBreakdown = {
  __typename?: 'EmissionsModeBreakdown';
  activity?: Maybe<Scalars['Float']['output']>;
  activity_unit?: Maybe<Scalars['String']['output']>;
  co2e: Scalars['Float']['output'];
  count?: Maybe<Scalars['Float']['output']>;
  intensity?: Maybe<Scalars['Float']['output']>;
  intensity_unit?: Maybe<Scalars['String']['output']>;
  label: EmissionModeEnum;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export enum FerryLoadCharacteristicsEnum {
  Accompanied = 'ACCOMPANIED',
  Unaccompanied = 'UNACCOMPANIED',
}

export type FreightContainer = {
  __typename?: 'FreightContainer';
  iso_type_code: Scalars['String']['output'];
  net_weight: ContainerWeight;
  quantity?: Maybe<Scalars['Float']['output']>;
};

export type FreightDistance = {
  __typename?: 'FreightDistance';
  unit?: Maybe<EmissionFreightDistanceUnitEnum>;
  value: Scalars['Float']['output'];
};

export type FreightFuelConsumption = {
  __typename?: 'FreightFuelConsumption';
  unit?: Maybe<EmissionFreightFuelConsumptionUnitEnum>;
  value: Scalars['Float']['output'];
};

export type FreightRoute = FreightRouteStops | FreightRouteVia;

export type FreightRouteStops = {
  __typename?: 'FreightRouteStops';
  stops: Array<LocationCode>;
};

export type FreightRouteVia = {
  __typename?: 'FreightRouteVia';
  via: Array<ViaLocation>;
};

export type FreightShipmentCalculationConfig = {
  __typename?: 'FreightShipmentCalculationConfig';
  auto_transshipment?: Maybe<Scalars['Boolean']['output']>;
  ferry_load_characteristics?: Maybe<FerryLoadCharacteristicsEnum>;
};

export type FreightVehicleDetails = {
  __typename?: 'FreightVehicleDetails';
  aircraft_code?: Maybe<Scalars['String']['output']>;
  carrier_code?: Maybe<Scalars['String']['output']>;
  carrier_name?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  emission_standard?: Maybe<EmissionFreightVehicleEmissionStandardEnum>;
  empty_running?: Maybe<Scalars['Float']['output']>;
  flight_no?: Maybe<Scalars['String']['output']>;
  fuel_consumption?: Maybe<FreightFuelConsumption>;
  fuel_type?: Maybe<CalculatorFreightFuelTypeEnum>;
  is_refrigerated?: Maybe<Scalars['Boolean']['output']>;
  load_factor?: Maybe<Scalars['Float']['output']>;
  load_type?: Maybe<EmissionFreightLoadTypeEnum>;
  vessel_id?: Maybe<Scalars['String']['output']>;
  vessel_name?: Maybe<Scalars['String']['output']>;
  voyage_no?: Maybe<Scalars['String']['output']>;
};

export type Geography = {
  __typename?: 'Geography';
  coordinates: Array<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
};

export type GetOffsettingLink = {
  __typename?: 'GetOffsettingLink';
  client: Client;
  emission?: Maybe<Emission>;
  offsetting_link: OffsettingLink;
  portfolios: Array<OffsettingLinkPortfolio>;
};

export type GhgBreakdown = {
  __typename?: 'GhgBreakdown';
  scope_1?: Maybe<Array<GhgBreakdownItem>>;
  scope_2?: Maybe<Array<GhgBreakdownItem>>;
  scope_3?: Maybe<Array<GhgBreakdownItem>>;
};

export type GhgBreakdownItem = {
  __typename?: 'GhgBreakdownItem';
  co2e: EmissionNumber;
  ghg: EmissionNumber;
  label: Scalars['String']['output'];
};

export type GhgMethodology = {
  __typename?: 'GhgMethodology';
  geocoding_breakdown: Array<GhgPercentageBreakdown>;
  methodology_breakdown: Array<GhgPercentageBreakdown>;
  routing_breakdown: Array<GhgPercentageBreakdown>;
  sources: Array<GhgSource>;
};

export type GhgPercentageBreakdown = {
  __typename?: 'GhgPercentageBreakdown';
  percentage: Scalars['Float']['output'];
  type: Scalars['String']['output'];
};

export type GhgReport = {
  __typename?: 'GhgReport';
  client: Client;
  client_user?: Maybe<ClientUser>;
  created_date: Scalars['DateTimeISO']['output'];
  filters: GhgReportFilters;
  id: Scalars['ID']['output'];
  is_branded?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  prepared_for?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  results: GhgReportResults;
  share_token: Scalars['ID']['output'];
  type: ReportTypeEnum;
  version_number: Scalars['Int']['output'];
  visibility: ReportVisibilityEnum;
};

export type GhgReportFilters = {
  __typename?: 'GhgReportFilters';
  client_identifier?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['DateTimeISO']['output']>;
  import_id?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type GhgReportResults = {
  __typename?: 'GhgReportResults';
  emissions_range_end_date?: Maybe<Scalars['DateTimeISO']['output']>;
  emissions_range_start_date?: Maybe<Scalars['DateTimeISO']['output']>;
  ghg_breakdown: GhgBreakdown;
  scope_breakdown: Array<ScopeBreakdown>;
  total_co2e: EmissionNumber;
  type_breakdown: TypeBreakdown;
};

export type GhgSource = {
  __typename?: 'GhgSource';
  name: Scalars['String']['output'];
  revision?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

export type GlecReport = {
  __typename?: 'GlecReport';
  client: Client;
  client_user?: Maybe<ClientUser>;
  created_date: Scalars['DateTimeISO']['output'];
  filters: GlecReportFilters;
  id: Scalars['ID']['output'];
  is_branded?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  prepared_for?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  results: GlecReportResults;
  share_token: Scalars['ID']['output'];
  type: ReportTypeEnum;
  version_number: Scalars['Int']['output'];
  visibility: ReportVisibilityEnum;
};

export type GlecReportBreakdown = {
  __typename?: 'GlecReportBreakdown';
  activity?: Maybe<Scalars['Float']['output']>;
  activity_unit?: Maybe<Scalars['String']['output']>;
  co2e: Scalars['Float']['output'];
  intensity?: Maybe<Scalars['Float']['output']>;
  intensity_unit?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
};

export type GlecReportByMode = {
  __typename?: 'GlecReportByMode';
  items: Array<GlecReportByModeItem>;
  main_data_type?: Maybe<Scalars['String']['output']>;
  mode: EmissionModeEnum;
};

export type GlecReportByModeItem = {
  __typename?: 'GlecReportByModeItem';
  activity?: Maybe<Scalars['Float']['output']>;
  activity_unit?: Maybe<Scalars['String']['output']>;
  co2e: Scalars['Float']['output'];
  intensity?: Maybe<Scalars['Float']['output']>;
  intensity_unit?: Maybe<Scalars['String']['output']>;
};

export type GlecReportFilters = {
  __typename?: 'GlecReportFilters';
  client_identifier?: Maybe<Scalars['String']['output']>;
  confirmed: Scalars['Boolean']['output'];
  confirmed_text?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['DateTimeISO']['output']>;
  import_id?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['DateTimeISO']['output']>;
  supply_chain_coverage: Scalars['String']['output'];
};

export type GlecReportResults = {
  __typename?: 'GlecReportResults';
  breakdown_by_carriage: Array<GlecReportBreakdown>;
  breakdown_by_data_type: Array<GlecReportBreakdown>;
  breakdown_by_mode: Array<GlecReportBreakdown>;
  breakdown_by_scope: Array<GlecReportBreakdown>;
  breakdown_by_type: Array<GlecReportBreakdown>;
  emissions_range_end_date?: Maybe<Scalars['DateTimeISO']['output']>;
  emissions_range_start_date?: Maybe<Scalars['DateTimeISO']['output']>;
  report_by_mode: Array<GlecReportByMode>;
  total_activity?: Maybe<Scalars['Float']['output']>;
  total_activity_unit?: Maybe<Scalars['String']['output']>;
  total_co2e: Scalars['Float']['output'];
  total_intensity?: Maybe<Scalars['Float']['output']>;
  total_intensity_unit?: Maybe<Scalars['String']['output']>;
};

export type ItemisedEmissionsReport = {
  __typename?: 'ItemisedEmissionsReport';
  client: Client;
  client_user?: Maybe<ClientUser>;
  created_date: Scalars['DateTimeISO']['output'];
  filters: ItemisedEmissionsReportFilters;
  id: Scalars['ID']['output'];
  is_branded?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  prepared_for?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  results: ItemisedEmissionsReportResults;
  share_token: Scalars['ID']['output'];
  type: ReportTypeEnum;
  version_number: Scalars['Int']['output'];
  visibility: ReportVisibilityEnum;
};

export type ItemisedEmissionsReportBreakdownByShipment = {
  __typename?: 'ItemisedEmissionsReportBreakdownByShipment';
  activity: Scalars['Float']['output'];
  activity_unit: Scalars['String']['output'];
  breakdown_by_type: Array<ItemisedEmissionsReportBreakdownByType>;
  co2e: Scalars['Float']['output'];
  date: Scalars['DateTimeISO']['output'];
  distance_km?: Maybe<Scalars['Float']['output']>;
  from_address?: Maybe<Scalars['String']['output']>;
  from_code?: Maybe<Scalars['String']['output']>;
  from_coordinates?: Maybe<Geography>;
  from_display?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  intensity: Scalars['Float']['output'];
  intensity_unit: Scalars['String']['output'];
  mode: EmissionModeEnum;
  quality?: Maybe<EmissionParentQuality>;
  shipment_identifier?: Maybe<Scalars['String']['output']>;
  teu?: Maybe<Scalars['Float']['output']>;
  to_address?: Maybe<Scalars['String']['output']>;
  to_code?: Maybe<Scalars['String']['output']>;
  to_coordinates?: Maybe<Geography>;
  to_display?: Maybe<Scalars['String']['output']>;
  weight_kg: Scalars['Float']['output'];
};

export type ItemisedEmissionsReportBreakdownByType = {
  __typename?: 'ItemisedEmissionsReportBreakdownByType';
  co2e: Scalars['Float']['output'];
  label: EmissionDetailTypeEnum;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type ItemisedEmissionsReportFilters = {
  __typename?: 'ItemisedEmissionsReportFilters';
  client_identifier: Scalars['String']['output'];
  end_date?: Maybe<Scalars['DateTimeISO']['output']>;
  import_id?: Maybe<Scalars['String']['output']>;
  shipment_identifier: Scalars['String']['output'];
  start_date?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type ItemisedEmissionsReportResults = {
  __typename?: 'ItemisedEmissionsReportResults';
  breakdown_by_shipment: Array<ItemisedEmissionsReportBreakdownByShipment>;
  breakdown_by_type: Array<ItemisedEmissionsReportBreakdownByType>;
  emissions_range_end_date?: Maybe<Scalars['DateTimeISO']['output']>;
  emissions_range_start_date?: Maybe<Scalars['DateTimeISO']['output']>;
  overall_quality: Scalars['Float']['output'];
  total_activity: Scalars['Float']['output'];
  total_activity_unit: Scalars['String']['output'];
  total_co2e: Scalars['Float']['output'];
  total_intensity: Scalars['Float']['output'];
  total_intensity_unit: Scalars['String']['output'];
  total_shipment: Scalars['Int']['output'];
  total_shipment_leg: Scalars['Int']['output'];
};

export type LocationAddress = {
  __typename?: 'LocationAddress';
  address: Scalars['String']['output'];
  country_code?: Maybe<Scalars['String']['output']>;
};

export type LocationCode = {
  __typename?: 'LocationCode';
  code: Scalars['String']['output'];
};

export type LocationCoordinates = {
  __typename?: 'LocationCoordinates';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type LocationCountry = {
  __typename?: 'LocationCountry';
  country_code: Scalars['String']['output'];
};

export type LocationFreight = LocationAddress | LocationCode | LocationCoordinates;

export type Metadata = {
  __typename?: 'Metadata';
  display: Scalars['String']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MovementWithDistance = {
  __typename?: 'MovementWithDistance';
  cargo_type?: Maybe<EmissionFreightCargoTypeEnum>;
  carriage?: Maybe<EmissionFreightCarriageEnum>;
  category?: Maybe<Scalars['Float']['output']>;
  config?: Maybe<Configuration>;
  date?: Maybe<Scalars['String']['output']>;
  distance: FreightDistance;
  from?: Maybe<LocationCountry>;
  scope?: Maybe<Scalars['Float']['output']>;
  to?: Maybe<LocationCountry>;
  vehicle: FreightVehicleDetails;
};

export type MovementWithLocation = {
  __typename?: 'MovementWithLocation';
  cargo_type?: Maybe<EmissionFreightCargoTypeEnum>;
  carriage?: Maybe<EmissionFreightCarriageEnum>;
  category?: Maybe<Scalars['Float']['output']>;
  config?: Maybe<Configuration>;
  date?: Maybe<Scalars['String']['output']>;
  from: LocationFreight;
  route?: Maybe<FreightRoute>;
  scope?: Maybe<Scalars['Float']['output']>;
  to: LocationFreight;
  vehicle: FreightVehicleDetails;
};

export type Mutation = {
  __typename?: 'Mutation';
  accept_data_collection_request: DataCollectionRequestPublic;
  decline_data_collection_request: DataCollectionRequestPublic;
  public_compute_freight_emission: Emission;
};

export type MutationAccept_Data_Collection_RequestArgs = {
  authentication_token: Scalars['String']['input'];
};

export type MutationDecline_Data_Collection_RequestArgs = {
  authentication_token: Scalars['String']['input'];
  note: Scalars['String']['input'];
};

export type MutationPublic_Compute_Freight_EmissionArgs = {
  input: Scalars['JSON']['input'];
  recaptcha_token: Scalars['String']['input'];
};

export type OffsettingLink = {
  __typename?: 'OffsettingLink';
  available_tonnes: Scalars['Float']['output'];
  created_by: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  currency: CurrencyEnum;
  id: Scalars['String']['output'];
  is_active: Scalars['Boolean']['output'];
  is_default: Scalars['Boolean']['output'];
  portfolios: Array<OffsettingLinkPortfolio>;
  portfolios_public_ids: Array<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  redirect_url?: Maybe<Scalars['String']['output']>;
  show_confirmation_page: Scalars['Boolean']['output'];
  type: OffsettingLinkTypeEnum;
  updated_by: ClientUser;
  updated_date: Scalars['DateTimeISO']['output'];
  url: Scalars['String']['output'];
};

export type OffsettingLinkPortfolio = {
  __typename?: 'OffsettingLinkPortfolio';
  allocated_weight_kg: Scalars['Float']['output'];
  allocations: Array<PortfolioAllocation>;
  available_tonnes: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  is_published: Scalars['Boolean']['output'];
  is_sold_out: Scalars['Boolean']['output'];
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
  portfolio_version: Scalars['String']['output'];
  prices: Array<OffsettingLinkPortfolioPrices>;
  public_id: Scalars['String']['output'];
  reserved_weight_kg: Scalars['Float']['output'];
  status: Scalars['String']['output'];
};

export type OffsettingLinkPortfolioPrices = {
  __typename?: 'OffsettingLinkPortfolioPrices';
  currency: Scalars['String']['output'];
  price_per_kg: Scalars['Float']['output'];
};

export enum OffsettingLinkTypeEnum {
  Amount = 'AMOUNT',
  Weight = 'WEIGHT',
}

export type Portfolio = {
  __typename?: 'Portfolio';
  allocated_weight_kg: Scalars['Float']['output'];
  allocations: Array<PortfolioAllocation>;
  created_date: Scalars['DateTimeISO']['output'];
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  distribution: PortfolioDistribution;
  is_published: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price_per_kg: Scalars['Float']['output'];
  public_id: Scalars['String']['output'];
  reserved_weight_kg: Scalars['Float']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export type PortfolioAllocation = {
  __typename?: 'PortfolioAllocation';
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  percentage: Scalars['Float']['output'];
  portfolio: Portfolio;
  project: Project;
  updated_date: Scalars['DateTimeISO']['output'];
};

export type PortfolioDistribution = {
  __typename?: 'PortfolioDistribution';
  avoidance: Scalars['Float']['output'];
  removal: Scalars['Float']['output'];
  totalAllocated: Scalars['Float']['output'];
};

export type Project = {
  __typename?: 'Project';
  avoidance: Scalars['Boolean']['output'];
  carbonplan_assessment?: Maybe<CarbonplanAssessment>;
  certifications?: Maybe<Array<ProjectCertification>>;
  coordinates?: Maybe<Geography>;
  country: Country;
  created_date: Scalars['DateTimeISO']['output'];
  description: Scalars['String']['output'];
  developer?: Maybe<ProjectDeveloper>;
  location?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
  project_type: ProjectType;
  public_id: Scalars['String']['output'];
  registry?: Maybe<Registry>;
  removal: Scalars['Boolean']['output'];
  sdgs?: Maybe<Array<SustainableDevelopmentGoalsEnum>>;
  updated_date: Scalars['DateTimeISO']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type ProjectCertification = {
  __typename?: 'ProjectCertification';
  id: Scalars['ID']['output'];
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
};

export type ProjectDeveloper = {
  __typename?: 'ProjectDeveloper';
  created_date: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type ProjectType = {
  __typename?: 'ProjectType';
  category: Scalars['String']['output'];
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export type Query = {
  __typename?: 'Query';
  data_collection_request: DataCollectionRequestPublic;
  public_calculator_input: CalculatorInput;
  public_calculator_usage: CalculatorUsage;
  public_emission: Emission;
  public_ghg_report: GhgReport;
  public_glec_report: GlecReport;
  public_itemised_emissions_report: ItemisedEmissionsReport;
  public_offsetting_link: GetOffsettingLink;
  public_report: Report;
  public_untracked_emission: Emission;
};

export type QueryData_Collection_RequestArgs = {
  authentication_token: Scalars['String']['input'];
};

export type QueryPublic_Calculator_InputArgs = {
  untracked_emission_public_id: Scalars['String']['input'];
};

export type QueryPublic_EmissionArgs = {
  share_token: Scalars['String']['input'];
};

export type QueryPublic_Ghg_ReportArgs = {
  share_token: Scalars['String']['input'];
};

export type QueryPublic_Glec_ReportArgs = {
  share_token: Scalars['String']['input'];
};

export type QueryPublic_Itemised_Emissions_ReportArgs = {
  share_token: Scalars['String']['input'];
};

export type QueryPublic_Offsetting_LinkArgs = {
  emission_id?: InputMaybe<Scalars['String']['input']>;
  include_emission: Scalars['Boolean']['input'];
  public_id: Scalars['String']['input'];
};

export type QueryPublic_ReportArgs = {
  share_token: Scalars['String']['input'];
};

export type QueryPublic_Untracked_EmissionArgs = {
  public_id: Scalars['String']['input'];
};

export enum RecommendationAction {
  OverrideDefaultValue = 'OVERRIDE_DEFAULT_VALUE',
  ProvideMissingValue = 'PROVIDE_MISSING_VALUE',
  ProvideMoreSpecificValue = 'PROVIDE_MORE_SPECIFIC_VALUE',
}

export type Registry = {
  __typename?: 'Registry';
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
  name_short?: Maybe<Scalars['String']['output']>;
  updated_date: Scalars['DateTimeISO']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type Report = {
  __typename?: 'Report';
  client: Client;
  client_user?: Maybe<ClientUser>;
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  prepared_for?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  share_token: Scalars['ID']['output'];
  type: ReportTypeEnum;
  version_number: Scalars['Int']['output'];
  visibility: ReportVisibilityEnum;
};

export enum ReportTypeEnum {
  Ghg = 'GHG',
  Glec = 'GLEC',
  ItemisedEmissions = 'ITEMISED_EMISSIONS',
}

export enum ReportVisibilityEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type RoadRoutingConfiguration = {
  __typename?: 'RoadRoutingConfiguration';
  ferry_routing_preference?: Maybe<RoadRoutingFerryPreferenceEnum>;
};

export enum RoadRoutingFerryPreferenceEnum {
  AvoidSoft = 'AVOID_SOFT',
  AvoidStrict = 'AVOID_STRICT',
  Preferred = 'PREFERRED',
}

export type RouteDetails = {
  __typename?: 'RouteDetails';
  country_code_alpha_2?: Maybe<Scalars['String']['output']>;
  is_ferry?: Maybe<Scalars['Boolean']['output']>;
  is_motorway?: Maybe<Scalars['Boolean']['output']>;
  is_train?: Maybe<Scalars['Boolean']['output']>;
  is_urban?: Maybe<Scalars['Boolean']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  stops?: Maybe<Array<RouteStop>>;
};

export type RouteStop = {
  __typename?: 'RouteStop';
  code?: Maybe<Scalars['String']['output']>;
  stop_info?: Maybe<RouteStopInfo>;
};

export type RouteStopInfo = {
  __typename?: 'RouteStopInfo';
  code_alpha_2?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RoutingConfiguration = {
  __typename?: 'RoutingConfiguration';
  road?: Maybe<RoadRoutingConfiguration>;
  sea?: Maybe<SeaRoutingConfiguration>;
};

export type S3Object = {
  __typename?: 'S3Object';
  bucket: Scalars['String']['output'];
  eTag: Scalars['String']['output'];
  key: Scalars['String']['output'];
  location: Scalars['String']['output'];
};

export type ScopeBreakdown = {
  __typename?: 'ScopeBreakdown';
  co2e: EmissionNumber;
  label: Scalars['String']['output'];
};

export enum SeaRoutingAreaEnum {
  Babelmandeb = 'BABELMANDEB',
  Bering = 'BERING',
  Corinth = 'CORINTH',
  Dover = 'DOVER',
  Gibraltar = 'GIBRALTAR',
  Hra = 'HRA',
  Ice = 'ICE',
  Kiel = 'KIEL',
  Magellan = 'MAGELLAN',
  Malacca = 'MALACCA',
  Northeast = 'NORTHEAST',
  Northwest = 'NORTHWEST',
  Panama = 'PANAMA',
  Suez = 'SUEZ',
}

export type SeaRoutingConfiguration = {
  __typename?: 'SeaRoutingConfiguration';
  restricted_areas?: Maybe<Array<SeaRoutingAreaEnum>>;
};

export enum SustainableDevelopmentGoalsEnum {
  Ace = 'ACE',
  Ca = 'CA',
  Cws = 'CWS',
  Dweg = 'DWEG',
  Ge = 'GE',
  Ghw = 'GHW',
  Iii = 'III',
  Lbw = 'LBW',
  Ll = 'LL',
  Np = 'NP',
  Pg = 'PG',
  Pjsi = 'PJSI',
  Qe = 'QE',
  Rcp = 'RCP',
  Ri = 'RI',
  Scc = 'SCC',
  Zh = 'ZH',
}

export enum TaxIdTypeEnum {
  AeTrn = 'AE_TRN',
  AuAbn = 'AU_ABN',
  BrCnpj = 'BR_CNPJ',
  BrCpf = 'BR_CPF',
  CaBn = 'CA_BN',
  CaGstHst = 'CA_GST_HST',
  CaPstBc = 'CA_PST_BC',
  CaPstMb = 'CA_PST_MB',
  CaPstSk = 'CA_PST_SK',
  CaQst = 'CA_QST',
  ChVat = 'CH_VAT',
  ClTin = 'CL_TIN',
  EsCif = 'ES_CIF',
  EuVat = 'EU_VAT',
  GbVat = 'GB_VAT',
  HkBr = 'HK_BR',
  IdNpwp = 'ID_NPWP',
  IlVat = 'IL_VAT',
  InGst = 'IN_GST',
  JpCn = 'JP_CN',
  JpRn = 'JP_RN',
  KrBrn = 'KR_BRN',
  LiUid = 'LI_UID',
  MxRfc = 'MX_RFC',
  MyFrp = 'MY_FRP',
  MyItn = 'MY_ITN',
  MySst = 'MY_SST',
  NoVat = 'NO_VAT',
  NzGst = 'NZ_GST',
  RuInn = 'RU_INN',
  RuKpp = 'RU_KPP',
  SaVat = 'SA_VAT',
  SgGst = 'SG_GST',
  SgUen = 'SG_UEN',
  ThVat = 'TH_VAT',
  TwVat = 'TW_VAT',
  UsEin = 'US_EIN',
  ZaVat = 'ZA_VAT',
}

export enum TeamAccessEnum {
  InviteOnly = 'INVITE_ONLY',
  OpenAccess = 'OPEN_ACCESS',
}

export type TypeBreakdown = {
  __typename?: 'TypeBreakdown';
  scope_1?: Maybe<Array<TypeBreakdownItem>>;
  scope_2?: Maybe<Array<TypeBreakdownItem>>;
  scope_3?: Maybe<Array<TypeBreakdownItem>>;
};

export type TypeBreakdownItem = {
  __typename?: 'TypeBreakdownItem';
  biogenic: BiogenicBreakdown;
  co2e: EmissionNumber;
  label: Scalars['String']['output'];
  methodology: GhgMethodology;
};

/** The status of the calculator usage */
export enum UsageStatus {
  Blocked = 'blocked',
  Near = 'near',
  Ok = 'ok',
}

export type ViaLocation = LocationCode | LocationCoordinates;

export type Weight = {
  __typename?: 'Weight';
  unit?: Maybe<EmissionFreightWeightUnitEnum>;
  value: Scalars['Float']['output'];
};

export type ComputePublicFreightEmissionMutationVariables = Exact<{
  input: Scalars['JSON']['input'];
  recaptchaToken: Scalars['String']['input'];
}>;

export type ComputePublicFreightEmissionMutation = {
  __typename?: 'Mutation';
  public_compute_freight_emission: {
    __typename?: 'Emission';
    id: string;
    public_id: string;
    import_id?: string | null;
    type: EmissionTypeEnum;
    co2e: number;
    intensity_normalized?: number | null;
    activity_normalized?: number | null;
    activity_normalized_unit?: EmissionFactorUnitEnum | null;
    date: string;
    created_date: string;
    distance_km?: number | null;
    sequence_no?: number | null;
    mode: EmissionModeEnum;
    weight_kg?: number | null;
    teu?: number | null;
    no_of_pax?: number | null;
    context_stringify?: string | null;
    source_type_name: EmissionSourceTypeEnum;
    has_parent: boolean;
    parent_id?: string | null;
    parent_public_id?: string | null;
    parent_child_count?: number | null;
    parent_share_token?: string | null;
    share_token: string;
    shipment_reference_number_metadata_key: string;
    shipment_reference_number_metadata_value?: string | null;
    client: {
      __typename?: 'Client';
      legal_name?: string | null;
      id: string;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
      branding?: { __typename?: 'ClientBranding'; brandColour: string; accentColour: string } | null;
    };
    meta_data?: Array<{ __typename?: 'Metadata'; key: string; display: string; value: string }> | null;
    quality?: {
      __typename?: 'EmissionQuality';
      overall?: EmissionQualityScoreEnum | null;
      origin_destination?: EmissionQualityScoreEnum | null;
      weight?: EmissionQualityScoreEnum | null;
      emission_intensity_factor?: EmissionQualityScoreEnum | null;
    } | null;
    parent_quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
    emission_children: Array<{
      __typename?: 'Emission';
      id: string;
      public_id: string;
      type: EmissionTypeEnum;
      mode: EmissionModeEnum;
      co2e: number;
      date: string;
      distance_km?: number | null;
      weight_kg?: number | null;
      teu?: number | null;
      context_stringify?: string | null;
      share_token: string;
      sequence_no?: number | null;
      quality?: {
        __typename?: 'EmissionQuality';
        overall?: EmissionQualityScoreEnum | null;
        origin_destination?: EmissionQualityScoreEnum | null;
        weight?: EmissionQualityScoreEnum | null;
        emission_intensity_factor?: EmissionQualityScoreEnum | null;
      } | null;
      issues?: Array<{
        __typename?: 'EmissionIssue';
        param: string;
        input_value?: string | null;
        assumed_value?: string | null;
        recommendations: Array<{
          __typename?: 'EmissionIssueRecommendation';
          action: RecommendationAction;
          params: Array<string>;
        }>;
      }> | null;
      freight?: {
        __typename?: 'EmissionFreight';
        carriage: EmissionFreightCarriageEnum;
        vehicle: EmissionFreightVehicleEnum;
        vehicle_code?: string | null;
        vehicle_category: EmissionFreightVehicleCategoryEnum;
        fuel_type?: EmissionFreightFuelTypeEnum | null;
        fuel_consumption?: number | null;
        fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
        vessel_id?: string | null;
        vessel_name?: string | null;
        carrier_code?: string | null;
        carrier_name?: string | null;
        aircraft_code?: string | null;
        flight_no?: string | null;
        emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
        load_factor?: number | null;
        load_type?: EmissionFreightLoadTypeEnum | null;
        empty_running?: number | null;
        cargo_type: EmissionFreightCargoTypeEnum;
        is_refrigerated: boolean;
        from_address?: string | null;
        from_code?: string | null;
        from_country_code?: string | null;
        from_country_name?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_code?: string | null;
        to_country_code?: string | null;
        to_display?: string | null;
        route_details?: {
          __typename?: 'RouteDetails';
          is_urban?: boolean | null;
          is_motorway?: boolean | null;
          is_ferry?: boolean | null;
          is_train?: boolean | null;
          country_code_alpha_2?: string | null;
        } | null;
        fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      freight_aggregated?: {
        __typename?: 'EmissionFreightAggregated';
        start_date: string;
        end_date: string;
        vehicle: EmissionFreightAggregatedVehicleEnum;
        vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
        vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
        fuel_type: EmissionFreightAggregatedFuelTypeEnum;
        fuel_consumption: number;
        fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
        fuel_used: number;
        fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
        load_factor: number;
        distance_km: number;
        weight_kg: number;
        vehicle_id?: string | null;
        fleet_id?: string | null;
      } | null;
      flights?: {
        __typename?: 'EmissionFlights';
        no_of_pax: number;
        cabin_class?: EmissionFlightsClassEnum | null;
        airline?: string | null;
        aircraft?: string | null;
        flight_no?: string | null;
        from_code: string;
        from_display?: string | null;
        to_code: string;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      last_mile?: {
        __typename?: 'EmissionLastMile';
        vehicle: EmissionLastMileVehicleEnum;
        vehicle_category: EmissionLastMileVehicleCategoryEnum;
        fuel_type?: EmissionLastMileFuelTypeEnum | null;
        make?: string | null;
        model?: string | null;
        from_address?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      ride_hailing?: {
        __typename?: 'EmissionRideHailing';
        vehicle: EmissionRideHailingVehicleEnum;
        vehicle_category: EmissionRideHailingVehicleCategoryEnum;
        fuel_type?: EmissionRideHailingFuelTypeEnum | null;
        make?: string | null;
        model?: string | null;
        no_of_pax?: number | null;
        from_address?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      details: Array<{
        __typename?: 'EmissionDetail';
        co2e: number;
        co2?: number | null;
        ch4?: number | null;
        n2o?: number | null;
        nox?: number | null;
        so2?: number | null;
        pm?: number | null;
        nmhc?: number | null;
        type?: EmissionDetailTypeEnum | null;
        subtype?: EmissionDetailSubtypeEnum | null;
        scope?: EmissionDetailScopeEnum | null;
        category?: EmissionDetailCategoryEnum | null;
      }>;
      emission_children: Array<{
        __typename?: 'Emission';
        id: string;
        public_id: string;
        type: EmissionTypeEnum;
        mode: EmissionModeEnum;
        co2e: number;
        date: string;
        distance_km?: number | null;
        weight_kg?: number | null;
        context_stringify?: string | null;
        sequence_no?: number | null;
        issues?: Array<{
          __typename?: 'EmissionIssue';
          param: string;
          input_value?: string | null;
          assumed_value?: string | null;
          recommendations: Array<{
            __typename?: 'EmissionIssueRecommendation';
            action: RecommendationAction;
            params: Array<string>;
          }>;
        }> | null;
        freight?: {
          __typename?: 'EmissionFreight';
          carriage: EmissionFreightCarriageEnum;
          vehicle: EmissionFreightVehicleEnum;
          vehicle_code?: string | null;
          vehicle_category: EmissionFreightVehicleCategoryEnum;
          fuel_type?: EmissionFreightFuelTypeEnum | null;
          fuel_consumption?: number | null;
          fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
          vessel_id?: string | null;
          vessel_name?: string | null;
          carrier_code?: string | null;
          carrier_name?: string | null;
          aircraft_code?: string | null;
          flight_no?: string | null;
          emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
          load_factor?: number | null;
          load_type?: EmissionFreightLoadTypeEnum | null;
          empty_running?: number | null;
          cargo_type: EmissionFreightCargoTypeEnum;
          is_refrigerated: boolean;
          from_address?: string | null;
          from_code?: string | null;
          from_country_code?: string | null;
          from_country_name?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_code?: string | null;
          to_country_code?: string | null;
          to_display?: string | null;
          route_details?: {
            __typename?: 'RouteDetails';
            is_urban?: boolean | null;
            is_motorway?: boolean | null;
            is_ferry?: boolean | null;
            is_train?: boolean | null;
            country_code_alpha_2?: string | null;
          } | null;
          fuel_identifier?: {
            __typename?: 'EmissionFreightFuelIdentifier';
            code: string;
            source?: string | null;
          } | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        } | null;
        freight_aggregated?: {
          __typename?: 'EmissionFreightAggregated';
          start_date: string;
          end_date: string;
          vehicle: EmissionFreightAggregatedVehicleEnum;
          vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
          vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
          fuel_type: EmissionFreightAggregatedFuelTypeEnum;
          fuel_consumption: number;
          fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
          fuel_used: number;
          fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
          load_factor: number;
          distance_km: number;
          weight_kg: number;
          vehicle_id?: string | null;
          fleet_id?: string | null;
        } | null;
        flights?: {
          __typename?: 'EmissionFlights';
          no_of_pax: number;
          cabin_class?: EmissionFlightsClassEnum | null;
          airline?: string | null;
          aircraft?: string | null;
          flight_no?: string | null;
          from_code: string;
          from_display?: string | null;
          to_code: string;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        } | null;
        last_mile?: {
          __typename?: 'EmissionLastMile';
          vehicle: EmissionLastMileVehicleEnum;
          vehicle_category: EmissionLastMileVehicleCategoryEnum;
          fuel_type?: EmissionLastMileFuelTypeEnum | null;
          make?: string | null;
          model?: string | null;
          from_address?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        } | null;
        ride_hailing?: {
          __typename?: 'EmissionRideHailing';
          vehicle: EmissionRideHailingVehicleEnum;
          vehicle_category: EmissionRideHailingVehicleCategoryEnum;
          fuel_type?: EmissionRideHailingFuelTypeEnum | null;
          make?: string | null;
          model?: string | null;
          no_of_pax?: number | null;
          from_address?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        } | null;
        details: Array<{
          __typename?: 'EmissionDetail';
          co2e: number;
          co2?: number | null;
          ch4?: number | null;
          n2o?: number | null;
          nox?: number | null;
          so2?: number | null;
          pm?: number | null;
          nmhc?: number | null;
          type?: EmissionDetailTypeEnum | null;
          subtype?: EmissionDetailSubtypeEnum | null;
          scope?: EmissionDetailScopeEnum | null;
          category?: EmissionDetailCategoryEnum | null;
        }>;
      }>;
    }>;
    last_mile?: {
      __typename?: 'EmissionLastMile';
      vehicle: EmissionLastMileVehicleEnum;
      vehicle_category: EmissionLastMileVehicleCategoryEnum;
      fuel_type?: EmissionLastMileFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    ride_hailing?: {
      __typename?: 'EmissionRideHailing';
      vehicle: EmissionRideHailingVehicleEnum;
      vehicle_category: EmissionRideHailingVehicleCategoryEnum;
      fuel_type?: EmissionRideHailingFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      no_of_pax?: number | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    flights?: {
      __typename?: 'EmissionFlights';
      no_of_pax: number;
      cabin_class?: EmissionFlightsClassEnum | null;
      airline?: string | null;
      aircraft?: string | null;
      flight_no?: string | null;
      from_code: string;
      from_display?: string | null;
      to_code: string;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    freight?: {
      __typename?: 'EmissionFreight';
      carriage: EmissionFreightCarriageEnum;
      vehicle: EmissionFreightVehicleEnum;
      vehicle_code?: string | null;
      vehicle_category: EmissionFreightVehicleCategoryEnum;
      fuel_type?: EmissionFreightFuelTypeEnum | null;
      fuel_consumption?: number | null;
      fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
      vessel_id?: string | null;
      vessel_name?: string | null;
      carrier_code?: string | null;
      carrier_name?: string | null;
      aircraft_code?: string | null;
      flight_no?: string | null;
      emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
      load_factor?: number | null;
      load_type?: EmissionFreightLoadTypeEnum | null;
      empty_running?: number | null;
      cargo_type: EmissionFreightCargoTypeEnum;
      is_refrigerated: boolean;
      from_address?: string | null;
      from_code?: string | null;
      from_country_code?: string | null;
      from_country_name?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_code?: string | null;
      to_country_code?: string | null;
      to_display?: string | null;
      route_details?: {
        __typename?: 'RouteDetails';
        country_code_alpha_2?: string | null;
        is_ferry?: boolean | null;
        is_train?: boolean | null;
        stops?: Array<{
          __typename?: 'RouteStop';
          code?: string | null;
          stop_info?: { __typename?: 'RouteStopInfo'; code_alpha_2?: string | null; name?: string | null } | null;
        }> | null;
      } | null;
      fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    details: Array<{
      __typename?: 'EmissionDetail';
      co2e: number;
      co2?: number | null;
      ch4?: number | null;
      n2o?: number | null;
      nox?: number | null;
      so2?: number | null;
      pm?: number | null;
      nmhc?: number | null;
      type?: EmissionDetailTypeEnum | null;
      subtype?: EmissionDetailSubtypeEnum | null;
      scope?: EmissionDetailScopeEnum | null;
      category?: EmissionDetailCategoryEnum | null;
    }>;
  };
};

export type GetCalculatorUsageQueryVariables = Exact<{ [key: string]: never }>;

export type GetCalculatorUsageQuery = {
  __typename?: 'Query';
  public_calculator_usage: { __typename?: 'CalculatorUsage'; status: UsageStatus };
};

export type AcceptPublicDataCollectionRequestByAuthenticationTokenMutationVariables = Exact<{
  authentication_token: Scalars['String']['input'];
}>;

export type AcceptPublicDataCollectionRequestByAuthenticationTokenMutation = {
  __typename?: 'Mutation';
  accept_data_collection_request: {
    __typename?: 'DataCollectionRequestPublic';
    is_authenticated: boolean;
    data_collection_request: {
      __typename?: 'DataCollectionRequest';
      id: string;
      public_id: string;
      state: DataCollectionRequestStateEnum;
      domain: string;
      recipients: Array<{ __typename?: 'ClientUser'; email: string }>;
      activities: Array<
        | {
            __typename?: 'DataCollectionRequestAcceptedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestApprovedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestCancelledActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestDataStagedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
            emissions_aggregations?: {
              __typename?: 'EmissionsAggregations';
              total_co2e: number;
              total_activity: number;
              total_activity_unit: string;
              total_intensity: number;
              total_intensity_unit: string;
              overall_quality: number;
              total_shipments: number;
              activity_start_date: string;
              activity_end_date: string;
              emissions_by_mode: Array<{
                __typename?: 'EmissionsModeBreakdown';
                label: EmissionModeEnum;
                percentage?: number | null;
                co2e: number;
                count?: number | null;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
              emissions_by_lifecycle: Array<{
                __typename?: 'EmissionsLifecycleBreakdown';
                label: EmissionDetailTypeEnum;
                percentage?: number | null;
                co2e: number;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
            } | null;
          }
        | {
            __typename?: 'DataCollectionRequestDeclinedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestDeliveryFailedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
          }
        | {
            __typename?: 'DataCollectionRequestRejectedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestRemindedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestRequestFailedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
          }
        | {
            __typename?: 'DataCollectionRequestRequestedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestSubmittedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
            emissions_aggregations?: {
              __typename?: 'EmissionsAggregations';
              total_co2e: number;
              total_activity: number;
              total_activity_unit: string;
              total_intensity: number;
              total_intensity_unit: string;
              overall_quality: number;
              total_shipments: number;
              activity_start_date: string;
              activity_end_date: string;
              emissions_by_mode: Array<{
                __typename?: 'EmissionsModeBreakdown';
                label: EmissionModeEnum;
                percentage?: number | null;
                co2e: number;
                count?: number | null;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
              emissions_by_lifecycle: Array<{
                __typename?: 'EmissionsLifecycleBreakdown';
                label: EmissionDetailTypeEnum;
                percentage?: number | null;
                co2e: number;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
            } | null;
          }
        | {
            __typename?: 'DataCollectionRequestViewedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
      >;
      data_collection: {
        __typename?: 'DataCollection';
        id: string;
        public_id: string;
        period_start_date: string;
        period_end_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      };
    };
  };
};

export type DeclinePublicDataCollectionRequestByAuthenticationTokenMutationVariables = Exact<{
  authentication_token: Scalars['String']['input'];
  note: Scalars['String']['input'];
}>;

export type DeclinePublicDataCollectionRequestByAuthenticationTokenMutation = {
  __typename?: 'Mutation';
  decline_data_collection_request: {
    __typename?: 'DataCollectionRequestPublic';
    is_authenticated: boolean;
    data_collection_request: {
      __typename?: 'DataCollectionRequest';
      id: string;
      public_id: string;
      state: DataCollectionRequestStateEnum;
      domain: string;
      recipients: Array<{ __typename?: 'ClientUser'; email: string }>;
      activities: Array<
        | {
            __typename?: 'DataCollectionRequestAcceptedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestApprovedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestCancelledActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestDataStagedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
            emissions_aggregations?: {
              __typename?: 'EmissionsAggregations';
              total_co2e: number;
              total_activity: number;
              total_activity_unit: string;
              total_intensity: number;
              total_intensity_unit: string;
              overall_quality: number;
              total_shipments: number;
              activity_start_date: string;
              activity_end_date: string;
              emissions_by_mode: Array<{
                __typename?: 'EmissionsModeBreakdown';
                label: EmissionModeEnum;
                percentage?: number | null;
                co2e: number;
                count?: number | null;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
              emissions_by_lifecycle: Array<{
                __typename?: 'EmissionsLifecycleBreakdown';
                label: EmissionDetailTypeEnum;
                percentage?: number | null;
                co2e: number;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
            } | null;
          }
        | {
            __typename?: 'DataCollectionRequestDeclinedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestDeliveryFailedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
          }
        | {
            __typename?: 'DataCollectionRequestRejectedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestRemindedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestRequestFailedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
          }
        | {
            __typename?: 'DataCollectionRequestRequestedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestSubmittedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
            emissions_aggregations?: {
              __typename?: 'EmissionsAggregations';
              total_co2e: number;
              total_activity: number;
              total_activity_unit: string;
              total_intensity: number;
              total_intensity_unit: string;
              overall_quality: number;
              total_shipments: number;
              activity_start_date: string;
              activity_end_date: string;
              emissions_by_mode: Array<{
                __typename?: 'EmissionsModeBreakdown';
                label: EmissionModeEnum;
                percentage?: number | null;
                co2e: number;
                count?: number | null;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
              emissions_by_lifecycle: Array<{
                __typename?: 'EmissionsLifecycleBreakdown';
                label: EmissionDetailTypeEnum;
                percentage?: number | null;
                co2e: number;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
            } | null;
          }
        | {
            __typename?: 'DataCollectionRequestViewedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
      >;
      data_collection: {
        __typename?: 'DataCollection';
        id: string;
        public_id: string;
        period_start_date: string;
        period_end_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      };
    };
  };
};

export type ClientProfile_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment = {
  __typename?: 'Client';
  public_id: string;
  legal_name?: string | null;
  avatar?: { __typename?: 'S3Object'; key: string } | null;
  logo?: { __typename?: 'S3Object'; key: string } | null;
};

export type ClientUserProfile_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment = {
  __typename?: 'ClientUser';
  first_name?: string | null;
  last_name?: string | null;
  email: string;
  avatar?: { __typename?: 'S3Object'; key: string } | null;
};

export type EmissionsModeBreakdown_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment = {
  __typename?: 'EmissionsModeBreakdown';
  label: EmissionModeEnum;
  percentage?: number | null;
  co2e: number;
  count?: number | null;
  activity?: number | null;
  activity_unit?: string | null;
  intensity?: number | null;
  intensity_unit?: string | null;
};

export type EmissionsLifecycleBreakdown_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment = {
  __typename?: 'EmissionsLifecycleBreakdown';
  label: EmissionDetailTypeEnum;
  percentage?: number | null;
  co2e: number;
  activity?: number | null;
  activity_unit?: string | null;
  intensity?: number | null;
  intensity_unit?: string | null;
};

export type EmissionsAggregations_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment = {
  __typename?: 'EmissionsAggregations';
  total_co2e: number;
  total_activity: number;
  total_activity_unit: string;
  total_intensity: number;
  total_intensity_unit: string;
  overall_quality: number;
  total_shipments: number;
  activity_start_date: string;
  activity_end_date: string;
  emissions_by_mode: Array<{
    __typename?: 'EmissionsModeBreakdown';
    label: EmissionModeEnum;
    percentage?: number | null;
    co2e: number;
    count?: number | null;
    activity?: number | null;
    activity_unit?: string | null;
    intensity?: number | null;
    intensity_unit?: string | null;
  }>;
  emissions_by_lifecycle: Array<{
    __typename?: 'EmissionsLifecycleBreakdown';
    label: EmissionDetailTypeEnum;
    percentage?: number | null;
    co2e: number;
    activity?: number | null;
    activity_unit?: string | null;
    intensity?: number | null;
    intensity_unit?: string | null;
  }>;
};

export type DataCollectionRequestRequestedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment = {
  __typename?: 'DataCollectionRequestRequestedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type DataCollectionRequestAcceptedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment = {
  __typename?: 'DataCollectionRequestAcceptedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type DataCollectionRequestDeclinedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment = {
  __typename?: 'DataCollectionRequestDeclinedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type DataCollectionRequestCancelledActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment = {
  __typename?: 'DataCollectionRequestCancelledActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type DataCollectionRequestDataStagedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment = {
  __typename?: 'DataCollectionRequestDataStagedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
  emissions_aggregations?: {
    __typename?: 'EmissionsAggregations';
    total_co2e: number;
    total_activity: number;
    total_activity_unit: string;
    total_intensity: number;
    total_intensity_unit: string;
    overall_quality: number;
    total_shipments: number;
    activity_start_date: string;
    activity_end_date: string;
    emissions_by_mode: Array<{
      __typename?: 'EmissionsModeBreakdown';
      label: EmissionModeEnum;
      percentage?: number | null;
      co2e: number;
      count?: number | null;
      activity?: number | null;
      activity_unit?: string | null;
      intensity?: number | null;
      intensity_unit?: string | null;
    }>;
    emissions_by_lifecycle: Array<{
      __typename?: 'EmissionsLifecycleBreakdown';
      label: EmissionDetailTypeEnum;
      percentage?: number | null;
      co2e: number;
      activity?: number | null;
      activity_unit?: string | null;
      intensity?: number | null;
      intensity_unit?: string | null;
    }>;
  } | null;
};

export type DataCollectionRequestSubmittedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment = {
  __typename?: 'DataCollectionRequestSubmittedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
  emissions_aggregations?: {
    __typename?: 'EmissionsAggregations';
    total_co2e: number;
    total_activity: number;
    total_activity_unit: string;
    total_intensity: number;
    total_intensity_unit: string;
    overall_quality: number;
    total_shipments: number;
    activity_start_date: string;
    activity_end_date: string;
    emissions_by_mode: Array<{
      __typename?: 'EmissionsModeBreakdown';
      label: EmissionModeEnum;
      percentage?: number | null;
      co2e: number;
      count?: number | null;
      activity?: number | null;
      activity_unit?: string | null;
      intensity?: number | null;
      intensity_unit?: string | null;
    }>;
    emissions_by_lifecycle: Array<{
      __typename?: 'EmissionsLifecycleBreakdown';
      label: EmissionDetailTypeEnum;
      percentage?: number | null;
      co2e: number;
      activity?: number | null;
      activity_unit?: string | null;
      intensity?: number | null;
      intensity_unit?: string | null;
    }>;
  } | null;
};

export type DataCollectionRequestRejectedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment = {
  __typename?: 'DataCollectionRequestRejectedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type DataCollectionRequestApprovedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment = {
  __typename?: 'DataCollectionRequestApprovedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type DataCollectionRequestRemindedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment = {
  __typename?: 'DataCollectionRequestRemindedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type DataCollectionRequestDeliveryFailedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment =
  {
    __typename?: 'DataCollectionRequestDeliveryFailedActivity';
    id: string;
    event: DataCollectionRequestEventEnum;
    note?: string | null;
    created_date: string;
  };

export type DataCollectionRequestRequestFailedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment =
  {
    __typename?: 'DataCollectionRequestRequestFailedActivity';
    id: string;
    event: DataCollectionRequestEventEnum;
    note?: string | null;
    created_date: string;
  };

export type DataCollectionRequestViewedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment = {
  __typename?: 'DataCollectionRequestViewedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type DataCollectionRequestPublic_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment = {
  __typename?: 'DataCollectionRequest';
  id: string;
  public_id: string;
  state: DataCollectionRequestStateEnum;
  domain: string;
  recipients: Array<{ __typename?: 'ClientUser'; email: string }>;
  activities: Array<
    | {
        __typename?: 'DataCollectionRequestAcceptedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      }
    | {
        __typename?: 'DataCollectionRequestApprovedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      }
    | {
        __typename?: 'DataCollectionRequestCancelledActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      }
    | {
        __typename?: 'DataCollectionRequestDataStagedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
        emissions_aggregations?: {
          __typename?: 'EmissionsAggregations';
          total_co2e: number;
          total_activity: number;
          total_activity_unit: string;
          total_intensity: number;
          total_intensity_unit: string;
          overall_quality: number;
          total_shipments: number;
          activity_start_date: string;
          activity_end_date: string;
          emissions_by_mode: Array<{
            __typename?: 'EmissionsModeBreakdown';
            label: EmissionModeEnum;
            percentage?: number | null;
            co2e: number;
            count?: number | null;
            activity?: number | null;
            activity_unit?: string | null;
            intensity?: number | null;
            intensity_unit?: string | null;
          }>;
          emissions_by_lifecycle: Array<{
            __typename?: 'EmissionsLifecycleBreakdown';
            label: EmissionDetailTypeEnum;
            percentage?: number | null;
            co2e: number;
            activity?: number | null;
            activity_unit?: string | null;
            intensity?: number | null;
            intensity_unit?: string | null;
          }>;
        } | null;
      }
    | {
        __typename?: 'DataCollectionRequestDeclinedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      }
    | {
        __typename?: 'DataCollectionRequestDeliveryFailedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
      }
    | {
        __typename?: 'DataCollectionRequestRejectedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      }
    | {
        __typename?: 'DataCollectionRequestRemindedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      }
    | {
        __typename?: 'DataCollectionRequestRequestFailedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
      }
    | {
        __typename?: 'DataCollectionRequestRequestedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      }
    | {
        __typename?: 'DataCollectionRequestSubmittedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
        emissions_aggregations?: {
          __typename?: 'EmissionsAggregations';
          total_co2e: number;
          total_activity: number;
          total_activity_unit: string;
          total_intensity: number;
          total_intensity_unit: string;
          overall_quality: number;
          total_shipments: number;
          activity_start_date: string;
          activity_end_date: string;
          emissions_by_mode: Array<{
            __typename?: 'EmissionsModeBreakdown';
            label: EmissionModeEnum;
            percentage?: number | null;
            co2e: number;
            count?: number | null;
            activity?: number | null;
            activity_unit?: string | null;
            intensity?: number | null;
            intensity_unit?: string | null;
          }>;
          emissions_by_lifecycle: Array<{
            __typename?: 'EmissionsLifecycleBreakdown';
            label: EmissionDetailTypeEnum;
            percentage?: number | null;
            co2e: number;
            activity?: number | null;
            activity_unit?: string | null;
            intensity?: number | null;
            intensity_unit?: string | null;
          }>;
        } | null;
      }
    | {
        __typename?: 'DataCollectionRequestViewedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      }
  >;
  data_collection: {
    __typename?: 'DataCollection';
    id: string;
    public_id: string;
    period_start_date: string;
    period_end_date: string;
    client: {
      __typename?: 'Client';
      public_id: string;
      legal_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
      logo?: { __typename?: 'S3Object'; key: string } | null;
    };
    client_user: {
      __typename?: 'ClientUser';
      first_name?: string | null;
      last_name?: string | null;
      email: string;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    };
  };
};

export type GetPublicDataCollectionRequestByAuthenticationTokenQueryVariables = Exact<{
  authentication_token: Scalars['String']['input'];
}>;

export type GetPublicDataCollectionRequestByAuthenticationTokenQuery = {
  __typename?: 'Query';
  data_collection_request: {
    __typename?: 'DataCollectionRequestPublic';
    is_authenticated: boolean;
    email: string;
    data_collection_request: {
      __typename?: 'DataCollectionRequest';
      id: string;
      public_id: string;
      state: DataCollectionRequestStateEnum;
      domain: string;
      recipients: Array<{ __typename?: 'ClientUser'; email: string }>;
      activities: Array<
        | {
            __typename?: 'DataCollectionRequestAcceptedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestApprovedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestCancelledActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestDataStagedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
            emissions_aggregations?: {
              __typename?: 'EmissionsAggregations';
              total_co2e: number;
              total_activity: number;
              total_activity_unit: string;
              total_intensity: number;
              total_intensity_unit: string;
              overall_quality: number;
              total_shipments: number;
              activity_start_date: string;
              activity_end_date: string;
              emissions_by_mode: Array<{
                __typename?: 'EmissionsModeBreakdown';
                label: EmissionModeEnum;
                percentage?: number | null;
                co2e: number;
                count?: number | null;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
              emissions_by_lifecycle: Array<{
                __typename?: 'EmissionsLifecycleBreakdown';
                label: EmissionDetailTypeEnum;
                percentage?: number | null;
                co2e: number;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
            } | null;
          }
        | {
            __typename?: 'DataCollectionRequestDeclinedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestDeliveryFailedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
          }
        | {
            __typename?: 'DataCollectionRequestRejectedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestRemindedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestRequestFailedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
          }
        | {
            __typename?: 'DataCollectionRequestRequestedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestSubmittedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
            emissions_aggregations?: {
              __typename?: 'EmissionsAggregations';
              total_co2e: number;
              total_activity: number;
              total_activity_unit: string;
              total_intensity: number;
              total_intensity_unit: string;
              overall_quality: number;
              total_shipments: number;
              activity_start_date: string;
              activity_end_date: string;
              emissions_by_mode: Array<{
                __typename?: 'EmissionsModeBreakdown';
                label: EmissionModeEnum;
                percentage?: number | null;
                co2e: number;
                count?: number | null;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
              emissions_by_lifecycle: Array<{
                __typename?: 'EmissionsLifecycleBreakdown';
                label: EmissionDetailTypeEnum;
                percentage?: number | null;
                co2e: number;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
            } | null;
          }
        | {
            __typename?: 'DataCollectionRequestViewedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
      >;
      data_collection: {
        __typename?: 'DataCollection';
        id: string;
        public_id: string;
        period_start_date: string;
        period_end_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      };
    };
  };
};

export type Base_FreightEmission_FromGetEmissionFragment = {
  __typename?: 'EmissionFreight';
  carriage: EmissionFreightCarriageEnum;
  vehicle: EmissionFreightVehicleEnum;
  vehicle_code?: string | null;
  vehicle_category: EmissionFreightVehicleCategoryEnum;
  fuel_type?: EmissionFreightFuelTypeEnum | null;
  fuel_consumption?: number | null;
  fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
  vessel_id?: string | null;
  vessel_name?: string | null;
  carrier_code?: string | null;
  carrier_name?: string | null;
  aircraft_code?: string | null;
  flight_no?: string | null;
  emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
  load_factor?: number | null;
  load_type?: EmissionFreightLoadTypeEnum | null;
  empty_running?: number | null;
  cargo_type: EmissionFreightCargoTypeEnum;
  is_refrigerated: boolean;
  from_address?: string | null;
  from_code?: string | null;
  from_country_code?: string | null;
  from_country_name?: string | null;
  from_display?: string | null;
  to_address?: string | null;
  to_code?: string | null;
  to_country_code?: string | null;
  to_display?: string | null;
  fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
  from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
};

export type FreightEmission_FromGetEmissionFragment = {
  __typename?: 'EmissionFreight';
  carriage: EmissionFreightCarriageEnum;
  vehicle: EmissionFreightVehicleEnum;
  vehicle_code?: string | null;
  vehicle_category: EmissionFreightVehicleCategoryEnum;
  fuel_type?: EmissionFreightFuelTypeEnum | null;
  fuel_consumption?: number | null;
  fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
  vessel_id?: string | null;
  vessel_name?: string | null;
  carrier_code?: string | null;
  carrier_name?: string | null;
  aircraft_code?: string | null;
  flight_no?: string | null;
  emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
  load_factor?: number | null;
  load_type?: EmissionFreightLoadTypeEnum | null;
  empty_running?: number | null;
  cargo_type: EmissionFreightCargoTypeEnum;
  is_refrigerated: boolean;
  from_address?: string | null;
  from_code?: string | null;
  from_country_code?: string | null;
  from_country_name?: string | null;
  from_display?: string | null;
  to_address?: string | null;
  to_code?: string | null;
  to_country_code?: string | null;
  to_display?: string | null;
  route_details?: {
    __typename?: 'RouteDetails';
    is_urban?: boolean | null;
    is_motorway?: boolean | null;
    is_ferry?: boolean | null;
    is_train?: boolean | null;
    country_code_alpha_2?: string | null;
  } | null;
  fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
  from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
};

export type FreightEmissionShipment_FromGetEmissionFragment = {
  __typename?: 'EmissionFreight';
  carriage: EmissionFreightCarriageEnum;
  vehicle: EmissionFreightVehicleEnum;
  vehicle_code?: string | null;
  vehicle_category: EmissionFreightVehicleCategoryEnum;
  fuel_type?: EmissionFreightFuelTypeEnum | null;
  fuel_consumption?: number | null;
  fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
  vessel_id?: string | null;
  vessel_name?: string | null;
  carrier_code?: string | null;
  carrier_name?: string | null;
  aircraft_code?: string | null;
  flight_no?: string | null;
  emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
  load_factor?: number | null;
  load_type?: EmissionFreightLoadTypeEnum | null;
  empty_running?: number | null;
  cargo_type: EmissionFreightCargoTypeEnum;
  is_refrigerated: boolean;
  from_address?: string | null;
  from_code?: string | null;
  from_country_code?: string | null;
  from_country_name?: string | null;
  from_display?: string | null;
  to_address?: string | null;
  to_code?: string | null;
  to_country_code?: string | null;
  to_display?: string | null;
  route_details?: {
    __typename?: 'RouteDetails';
    country_code_alpha_2?: string | null;
    is_ferry?: boolean | null;
    is_train?: boolean | null;
    stops?: Array<{
      __typename?: 'RouteStop';
      code?: string | null;
      stop_info?: { __typename?: 'RouteStopInfo'; code_alpha_2?: string | null; name?: string | null } | null;
    }> | null;
  } | null;
  fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
  from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
};

export type FreightAggregatedEmission_FromGetEmissionFragment = {
  __typename?: 'EmissionFreightAggregated';
  start_date: string;
  end_date: string;
  vehicle: EmissionFreightAggregatedVehicleEnum;
  vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
  vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
  fuel_type: EmissionFreightAggregatedFuelTypeEnum;
  fuel_consumption: number;
  fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
  fuel_used: number;
  fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
  load_factor: number;
  distance_km: number;
  weight_kg: number;
  vehicle_id?: string | null;
  fleet_id?: string | null;
};

export type LastMileEmission_FromGetEmissionFragment = {
  __typename?: 'EmissionLastMile';
  vehicle: EmissionLastMileVehicleEnum;
  vehicle_category: EmissionLastMileVehicleCategoryEnum;
  fuel_type?: EmissionLastMileFuelTypeEnum | null;
  make?: string | null;
  model?: string | null;
  from_address?: string | null;
  from_display?: string | null;
  to_address?: string | null;
  to_display?: string | null;
  from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
};

export type RideHailingEmission_FromGetEmissionFragment = {
  __typename?: 'EmissionRideHailing';
  vehicle: EmissionRideHailingVehicleEnum;
  vehicle_category: EmissionRideHailingVehicleCategoryEnum;
  fuel_type?: EmissionRideHailingFuelTypeEnum | null;
  make?: string | null;
  model?: string | null;
  no_of_pax?: number | null;
  from_address?: string | null;
  from_display?: string | null;
  to_address?: string | null;
  to_display?: string | null;
  from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
};

export type FlightsEmission_FromGetEmissionFragment = {
  __typename?: 'EmissionFlights';
  no_of_pax: number;
  cabin_class?: EmissionFlightsClassEnum | null;
  airline?: string | null;
  aircraft?: string | null;
  flight_no?: string | null;
  from_code: string;
  from_display?: string | null;
  to_code: string;
  to_display?: string | null;
  from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
};

export type SegmentEmissions_FromGetEmissionFragment = {
  __typename?: 'Emission';
  id: string;
  public_id: string;
  type: EmissionTypeEnum;
  mode: EmissionModeEnum;
  co2e: number;
  date: string;
  distance_km?: number | null;
  weight_kg?: number | null;
  context_stringify?: string | null;
  sequence_no?: number | null;
  issues?: Array<{
    __typename?: 'EmissionIssue';
    param: string;
    input_value?: string | null;
    assumed_value?: string | null;
    recommendations: Array<{
      __typename?: 'EmissionIssueRecommendation';
      action: RecommendationAction;
      params: Array<string>;
    }>;
  }> | null;
  freight?: {
    __typename?: 'EmissionFreight';
    carriage: EmissionFreightCarriageEnum;
    vehicle: EmissionFreightVehicleEnum;
    vehicle_code?: string | null;
    vehicle_category: EmissionFreightVehicleCategoryEnum;
    fuel_type?: EmissionFreightFuelTypeEnum | null;
    fuel_consumption?: number | null;
    fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
    vessel_id?: string | null;
    vessel_name?: string | null;
    carrier_code?: string | null;
    carrier_name?: string | null;
    aircraft_code?: string | null;
    flight_no?: string | null;
    emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
    load_factor?: number | null;
    load_type?: EmissionFreightLoadTypeEnum | null;
    empty_running?: number | null;
    cargo_type: EmissionFreightCargoTypeEnum;
    is_refrigerated: boolean;
    from_address?: string | null;
    from_code?: string | null;
    from_country_code?: string | null;
    from_country_name?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_code?: string | null;
    to_country_code?: string | null;
    to_display?: string | null;
    route_details?: {
      __typename?: 'RouteDetails';
      is_urban?: boolean | null;
      is_motorway?: boolean | null;
      is_ferry?: boolean | null;
      is_train?: boolean | null;
      country_code_alpha_2?: string | null;
    } | null;
    fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  } | null;
  freight_aggregated?: {
    __typename?: 'EmissionFreightAggregated';
    start_date: string;
    end_date: string;
    vehicle: EmissionFreightAggregatedVehicleEnum;
    vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
    vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
    fuel_type: EmissionFreightAggregatedFuelTypeEnum;
    fuel_consumption: number;
    fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
    fuel_used: number;
    fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
    load_factor: number;
    distance_km: number;
    weight_kg: number;
    vehicle_id?: string | null;
    fleet_id?: string | null;
  } | null;
  flights?: {
    __typename?: 'EmissionFlights';
    no_of_pax: number;
    cabin_class?: EmissionFlightsClassEnum | null;
    airline?: string | null;
    aircraft?: string | null;
    flight_no?: string | null;
    from_code: string;
    from_display?: string | null;
    to_code: string;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  } | null;
  last_mile?: {
    __typename?: 'EmissionLastMile';
    vehicle: EmissionLastMileVehicleEnum;
    vehicle_category: EmissionLastMileVehicleCategoryEnum;
    fuel_type?: EmissionLastMileFuelTypeEnum | null;
    make?: string | null;
    model?: string | null;
    from_address?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  } | null;
  ride_hailing?: {
    __typename?: 'EmissionRideHailing';
    vehicle: EmissionRideHailingVehicleEnum;
    vehicle_category: EmissionRideHailingVehicleCategoryEnum;
    fuel_type?: EmissionRideHailingFuelTypeEnum | null;
    make?: string | null;
    model?: string | null;
    no_of_pax?: number | null;
    from_address?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  } | null;
  details: Array<{
    __typename?: 'EmissionDetail';
    co2e: number;
    co2?: number | null;
    ch4?: number | null;
    n2o?: number | null;
    nox?: number | null;
    so2?: number | null;
    pm?: number | null;
    nmhc?: number | null;
    type?: EmissionDetailTypeEnum | null;
    subtype?: EmissionDetailSubtypeEnum | null;
    scope?: EmissionDetailScopeEnum | null;
    category?: EmissionDetailCategoryEnum | null;
  }>;
};

export type ChildEmissions_FromGetEmissionFragment = {
  __typename?: 'Emission';
  id: string;
  public_id: string;
  type: EmissionTypeEnum;
  mode: EmissionModeEnum;
  co2e: number;
  date: string;
  distance_km?: number | null;
  weight_kg?: number | null;
  teu?: number | null;
  context_stringify?: string | null;
  share_token: string;
  sequence_no?: number | null;
  quality?: {
    __typename?: 'EmissionQuality';
    overall?: EmissionQualityScoreEnum | null;
    origin_destination?: EmissionQualityScoreEnum | null;
    weight?: EmissionQualityScoreEnum | null;
    emission_intensity_factor?: EmissionQualityScoreEnum | null;
  } | null;
  issues?: Array<{
    __typename?: 'EmissionIssue';
    param: string;
    input_value?: string | null;
    assumed_value?: string | null;
    recommendations: Array<{
      __typename?: 'EmissionIssueRecommendation';
      action: RecommendationAction;
      params: Array<string>;
    }>;
  }> | null;
  freight?: {
    __typename?: 'EmissionFreight';
    carriage: EmissionFreightCarriageEnum;
    vehicle: EmissionFreightVehicleEnum;
    vehicle_code?: string | null;
    vehicle_category: EmissionFreightVehicleCategoryEnum;
    fuel_type?: EmissionFreightFuelTypeEnum | null;
    fuel_consumption?: number | null;
    fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
    vessel_id?: string | null;
    vessel_name?: string | null;
    carrier_code?: string | null;
    carrier_name?: string | null;
    aircraft_code?: string | null;
    flight_no?: string | null;
    emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
    load_factor?: number | null;
    load_type?: EmissionFreightLoadTypeEnum | null;
    empty_running?: number | null;
    cargo_type: EmissionFreightCargoTypeEnum;
    is_refrigerated: boolean;
    from_address?: string | null;
    from_code?: string | null;
    from_country_code?: string | null;
    from_country_name?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_code?: string | null;
    to_country_code?: string | null;
    to_display?: string | null;
    route_details?: {
      __typename?: 'RouteDetails';
      is_urban?: boolean | null;
      is_motorway?: boolean | null;
      is_ferry?: boolean | null;
      is_train?: boolean | null;
      country_code_alpha_2?: string | null;
    } | null;
    fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  } | null;
  freight_aggregated?: {
    __typename?: 'EmissionFreightAggregated';
    start_date: string;
    end_date: string;
    vehicle: EmissionFreightAggregatedVehicleEnum;
    vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
    vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
    fuel_type: EmissionFreightAggregatedFuelTypeEnum;
    fuel_consumption: number;
    fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
    fuel_used: number;
    fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
    load_factor: number;
    distance_km: number;
    weight_kg: number;
    vehicle_id?: string | null;
    fleet_id?: string | null;
  } | null;
  flights?: {
    __typename?: 'EmissionFlights';
    no_of_pax: number;
    cabin_class?: EmissionFlightsClassEnum | null;
    airline?: string | null;
    aircraft?: string | null;
    flight_no?: string | null;
    from_code: string;
    from_display?: string | null;
    to_code: string;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  } | null;
  last_mile?: {
    __typename?: 'EmissionLastMile';
    vehicle: EmissionLastMileVehicleEnum;
    vehicle_category: EmissionLastMileVehicleCategoryEnum;
    fuel_type?: EmissionLastMileFuelTypeEnum | null;
    make?: string | null;
    model?: string | null;
    from_address?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  } | null;
  ride_hailing?: {
    __typename?: 'EmissionRideHailing';
    vehicle: EmissionRideHailingVehicleEnum;
    vehicle_category: EmissionRideHailingVehicleCategoryEnum;
    fuel_type?: EmissionRideHailingFuelTypeEnum | null;
    make?: string | null;
    model?: string | null;
    no_of_pax?: number | null;
    from_address?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  } | null;
  details: Array<{
    __typename?: 'EmissionDetail';
    co2e: number;
    co2?: number | null;
    ch4?: number | null;
    n2o?: number | null;
    nox?: number | null;
    so2?: number | null;
    pm?: number | null;
    nmhc?: number | null;
    type?: EmissionDetailTypeEnum | null;
    subtype?: EmissionDetailSubtypeEnum | null;
    scope?: EmissionDetailScopeEnum | null;
    category?: EmissionDetailCategoryEnum | null;
  }>;
  emission_children: Array<{
    __typename?: 'Emission';
    id: string;
    public_id: string;
    type: EmissionTypeEnum;
    mode: EmissionModeEnum;
    co2e: number;
    date: string;
    distance_km?: number | null;
    weight_kg?: number | null;
    context_stringify?: string | null;
    sequence_no?: number | null;
    issues?: Array<{
      __typename?: 'EmissionIssue';
      param: string;
      input_value?: string | null;
      assumed_value?: string | null;
      recommendations: Array<{
        __typename?: 'EmissionIssueRecommendation';
        action: RecommendationAction;
        params: Array<string>;
      }>;
    }> | null;
    freight?: {
      __typename?: 'EmissionFreight';
      carriage: EmissionFreightCarriageEnum;
      vehicle: EmissionFreightVehicleEnum;
      vehicle_code?: string | null;
      vehicle_category: EmissionFreightVehicleCategoryEnum;
      fuel_type?: EmissionFreightFuelTypeEnum | null;
      fuel_consumption?: number | null;
      fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
      vessel_id?: string | null;
      vessel_name?: string | null;
      carrier_code?: string | null;
      carrier_name?: string | null;
      aircraft_code?: string | null;
      flight_no?: string | null;
      emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
      load_factor?: number | null;
      load_type?: EmissionFreightLoadTypeEnum | null;
      empty_running?: number | null;
      cargo_type: EmissionFreightCargoTypeEnum;
      is_refrigerated: boolean;
      from_address?: string | null;
      from_code?: string | null;
      from_country_code?: string | null;
      from_country_name?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_code?: string | null;
      to_country_code?: string | null;
      to_display?: string | null;
      route_details?: {
        __typename?: 'RouteDetails';
        is_urban?: boolean | null;
        is_motorway?: boolean | null;
        is_ferry?: boolean | null;
        is_train?: boolean | null;
        country_code_alpha_2?: string | null;
      } | null;
      fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    freight_aggregated?: {
      __typename?: 'EmissionFreightAggregated';
      start_date: string;
      end_date: string;
      vehicle: EmissionFreightAggregatedVehicleEnum;
      vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
      vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
      fuel_type: EmissionFreightAggregatedFuelTypeEnum;
      fuel_consumption: number;
      fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
      fuel_used: number;
      fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
      load_factor: number;
      distance_km: number;
      weight_kg: number;
      vehicle_id?: string | null;
      fleet_id?: string | null;
    } | null;
    flights?: {
      __typename?: 'EmissionFlights';
      no_of_pax: number;
      cabin_class?: EmissionFlightsClassEnum | null;
      airline?: string | null;
      aircraft?: string | null;
      flight_no?: string | null;
      from_code: string;
      from_display?: string | null;
      to_code: string;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    last_mile?: {
      __typename?: 'EmissionLastMile';
      vehicle: EmissionLastMileVehicleEnum;
      vehicle_category: EmissionLastMileVehicleCategoryEnum;
      fuel_type?: EmissionLastMileFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    ride_hailing?: {
      __typename?: 'EmissionRideHailing';
      vehicle: EmissionRideHailingVehicleEnum;
      vehicle_category: EmissionRideHailingVehicleCategoryEnum;
      fuel_type?: EmissionRideHailingFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      no_of_pax?: number | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    details: Array<{
      __typename?: 'EmissionDetail';
      co2e: number;
      co2?: number | null;
      ch4?: number | null;
      n2o?: number | null;
      nox?: number | null;
      so2?: number | null;
      pm?: number | null;
      nmhc?: number | null;
      type?: EmissionDetailTypeEnum | null;
      subtype?: EmissionDetailSubtypeEnum | null;
      scope?: EmissionDetailScopeEnum | null;
      category?: EmissionDetailCategoryEnum | null;
    }>;
  }>;
};

export type Emission_FromGetEmissionFragment = {
  __typename?: 'Emission';
  id: string;
  public_id: string;
  import_id?: string | null;
  type: EmissionTypeEnum;
  co2e: number;
  intensity_normalized?: number | null;
  activity_normalized?: number | null;
  activity_normalized_unit?: EmissionFactorUnitEnum | null;
  date: string;
  created_date: string;
  distance_km?: number | null;
  sequence_no?: number | null;
  mode: EmissionModeEnum;
  weight_kg?: number | null;
  teu?: number | null;
  no_of_pax?: number | null;
  context_stringify?: string | null;
  source_type_name: EmissionSourceTypeEnum;
  has_parent: boolean;
  parent_id?: string | null;
  parent_public_id?: string | null;
  parent_child_count?: number | null;
  parent_share_token?: string | null;
  share_token: string;
  shipment_reference_number_metadata_key: string;
  shipment_reference_number_metadata_value?: string | null;
  client: {
    __typename?: 'Client';
    legal_name?: string | null;
    id: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    branding?: { __typename?: 'ClientBranding'; brandColour: string; accentColour: string } | null;
  };
  meta_data?: Array<{ __typename?: 'Metadata'; key: string; display: string; value: string }> | null;
  quality?: {
    __typename?: 'EmissionQuality';
    overall?: EmissionQualityScoreEnum | null;
    origin_destination?: EmissionQualityScoreEnum | null;
    weight?: EmissionQualityScoreEnum | null;
    emission_intensity_factor?: EmissionQualityScoreEnum | null;
  } | null;
  parent_quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
  emission_children: Array<{
    __typename?: 'Emission';
    id: string;
    public_id: string;
    type: EmissionTypeEnum;
    mode: EmissionModeEnum;
    co2e: number;
    date: string;
    distance_km?: number | null;
    weight_kg?: number | null;
    teu?: number | null;
    context_stringify?: string | null;
    share_token: string;
    sequence_no?: number | null;
    quality?: {
      __typename?: 'EmissionQuality';
      overall?: EmissionQualityScoreEnum | null;
      origin_destination?: EmissionQualityScoreEnum | null;
      weight?: EmissionQualityScoreEnum | null;
      emission_intensity_factor?: EmissionQualityScoreEnum | null;
    } | null;
    issues?: Array<{
      __typename?: 'EmissionIssue';
      param: string;
      input_value?: string | null;
      assumed_value?: string | null;
      recommendations: Array<{
        __typename?: 'EmissionIssueRecommendation';
        action: RecommendationAction;
        params: Array<string>;
      }>;
    }> | null;
    freight?: {
      __typename?: 'EmissionFreight';
      carriage: EmissionFreightCarriageEnum;
      vehicle: EmissionFreightVehicleEnum;
      vehicle_code?: string | null;
      vehicle_category: EmissionFreightVehicleCategoryEnum;
      fuel_type?: EmissionFreightFuelTypeEnum | null;
      fuel_consumption?: number | null;
      fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
      vessel_id?: string | null;
      vessel_name?: string | null;
      carrier_code?: string | null;
      carrier_name?: string | null;
      aircraft_code?: string | null;
      flight_no?: string | null;
      emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
      load_factor?: number | null;
      load_type?: EmissionFreightLoadTypeEnum | null;
      empty_running?: number | null;
      cargo_type: EmissionFreightCargoTypeEnum;
      is_refrigerated: boolean;
      from_address?: string | null;
      from_code?: string | null;
      from_country_code?: string | null;
      from_country_name?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_code?: string | null;
      to_country_code?: string | null;
      to_display?: string | null;
      route_details?: {
        __typename?: 'RouteDetails';
        is_urban?: boolean | null;
        is_motorway?: boolean | null;
        is_ferry?: boolean | null;
        is_train?: boolean | null;
        country_code_alpha_2?: string | null;
      } | null;
      fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    freight_aggregated?: {
      __typename?: 'EmissionFreightAggregated';
      start_date: string;
      end_date: string;
      vehicle: EmissionFreightAggregatedVehicleEnum;
      vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
      vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
      fuel_type: EmissionFreightAggregatedFuelTypeEnum;
      fuel_consumption: number;
      fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
      fuel_used: number;
      fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
      load_factor: number;
      distance_km: number;
      weight_kg: number;
      vehicle_id?: string | null;
      fleet_id?: string | null;
    } | null;
    flights?: {
      __typename?: 'EmissionFlights';
      no_of_pax: number;
      cabin_class?: EmissionFlightsClassEnum | null;
      airline?: string | null;
      aircraft?: string | null;
      flight_no?: string | null;
      from_code: string;
      from_display?: string | null;
      to_code: string;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    last_mile?: {
      __typename?: 'EmissionLastMile';
      vehicle: EmissionLastMileVehicleEnum;
      vehicle_category: EmissionLastMileVehicleCategoryEnum;
      fuel_type?: EmissionLastMileFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    ride_hailing?: {
      __typename?: 'EmissionRideHailing';
      vehicle: EmissionRideHailingVehicleEnum;
      vehicle_category: EmissionRideHailingVehicleCategoryEnum;
      fuel_type?: EmissionRideHailingFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      no_of_pax?: number | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    details: Array<{
      __typename?: 'EmissionDetail';
      co2e: number;
      co2?: number | null;
      ch4?: number | null;
      n2o?: number | null;
      nox?: number | null;
      so2?: number | null;
      pm?: number | null;
      nmhc?: number | null;
      type?: EmissionDetailTypeEnum | null;
      subtype?: EmissionDetailSubtypeEnum | null;
      scope?: EmissionDetailScopeEnum | null;
      category?: EmissionDetailCategoryEnum | null;
    }>;
    emission_children: Array<{
      __typename?: 'Emission';
      id: string;
      public_id: string;
      type: EmissionTypeEnum;
      mode: EmissionModeEnum;
      co2e: number;
      date: string;
      distance_km?: number | null;
      weight_kg?: number | null;
      context_stringify?: string | null;
      sequence_no?: number | null;
      issues?: Array<{
        __typename?: 'EmissionIssue';
        param: string;
        input_value?: string | null;
        assumed_value?: string | null;
        recommendations: Array<{
          __typename?: 'EmissionIssueRecommendation';
          action: RecommendationAction;
          params: Array<string>;
        }>;
      }> | null;
      freight?: {
        __typename?: 'EmissionFreight';
        carriage: EmissionFreightCarriageEnum;
        vehicle: EmissionFreightVehicleEnum;
        vehicle_code?: string | null;
        vehicle_category: EmissionFreightVehicleCategoryEnum;
        fuel_type?: EmissionFreightFuelTypeEnum | null;
        fuel_consumption?: number | null;
        fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
        vessel_id?: string | null;
        vessel_name?: string | null;
        carrier_code?: string | null;
        carrier_name?: string | null;
        aircraft_code?: string | null;
        flight_no?: string | null;
        emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
        load_factor?: number | null;
        load_type?: EmissionFreightLoadTypeEnum | null;
        empty_running?: number | null;
        cargo_type: EmissionFreightCargoTypeEnum;
        is_refrigerated: boolean;
        from_address?: string | null;
        from_code?: string | null;
        from_country_code?: string | null;
        from_country_name?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_code?: string | null;
        to_country_code?: string | null;
        to_display?: string | null;
        route_details?: {
          __typename?: 'RouteDetails';
          is_urban?: boolean | null;
          is_motorway?: boolean | null;
          is_ferry?: boolean | null;
          is_train?: boolean | null;
          country_code_alpha_2?: string | null;
        } | null;
        fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      freight_aggregated?: {
        __typename?: 'EmissionFreightAggregated';
        start_date: string;
        end_date: string;
        vehicle: EmissionFreightAggregatedVehicleEnum;
        vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
        vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
        fuel_type: EmissionFreightAggregatedFuelTypeEnum;
        fuel_consumption: number;
        fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
        fuel_used: number;
        fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
        load_factor: number;
        distance_km: number;
        weight_kg: number;
        vehicle_id?: string | null;
        fleet_id?: string | null;
      } | null;
      flights?: {
        __typename?: 'EmissionFlights';
        no_of_pax: number;
        cabin_class?: EmissionFlightsClassEnum | null;
        airline?: string | null;
        aircraft?: string | null;
        flight_no?: string | null;
        from_code: string;
        from_display?: string | null;
        to_code: string;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      last_mile?: {
        __typename?: 'EmissionLastMile';
        vehicle: EmissionLastMileVehicleEnum;
        vehicle_category: EmissionLastMileVehicleCategoryEnum;
        fuel_type?: EmissionLastMileFuelTypeEnum | null;
        make?: string | null;
        model?: string | null;
        from_address?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      ride_hailing?: {
        __typename?: 'EmissionRideHailing';
        vehicle: EmissionRideHailingVehicleEnum;
        vehicle_category: EmissionRideHailingVehicleCategoryEnum;
        fuel_type?: EmissionRideHailingFuelTypeEnum | null;
        make?: string | null;
        model?: string | null;
        no_of_pax?: number | null;
        from_address?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      details: Array<{
        __typename?: 'EmissionDetail';
        co2e: number;
        co2?: number | null;
        ch4?: number | null;
        n2o?: number | null;
        nox?: number | null;
        so2?: number | null;
        pm?: number | null;
        nmhc?: number | null;
        type?: EmissionDetailTypeEnum | null;
        subtype?: EmissionDetailSubtypeEnum | null;
        scope?: EmissionDetailScopeEnum | null;
        category?: EmissionDetailCategoryEnum | null;
      }>;
    }>;
  }>;
  last_mile?: {
    __typename?: 'EmissionLastMile';
    vehicle: EmissionLastMileVehicleEnum;
    vehicle_category: EmissionLastMileVehicleCategoryEnum;
    fuel_type?: EmissionLastMileFuelTypeEnum | null;
    make?: string | null;
    model?: string | null;
    from_address?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  } | null;
  ride_hailing?: {
    __typename?: 'EmissionRideHailing';
    vehicle: EmissionRideHailingVehicleEnum;
    vehicle_category: EmissionRideHailingVehicleCategoryEnum;
    fuel_type?: EmissionRideHailingFuelTypeEnum | null;
    make?: string | null;
    model?: string | null;
    no_of_pax?: number | null;
    from_address?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  } | null;
  flights?: {
    __typename?: 'EmissionFlights';
    no_of_pax: number;
    cabin_class?: EmissionFlightsClassEnum | null;
    airline?: string | null;
    aircraft?: string | null;
    flight_no?: string | null;
    from_code: string;
    from_display?: string | null;
    to_code: string;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  } | null;
  freight?: {
    __typename?: 'EmissionFreight';
    carriage: EmissionFreightCarriageEnum;
    vehicle: EmissionFreightVehicleEnum;
    vehicle_code?: string | null;
    vehicle_category: EmissionFreightVehicleCategoryEnum;
    fuel_type?: EmissionFreightFuelTypeEnum | null;
    fuel_consumption?: number | null;
    fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
    vessel_id?: string | null;
    vessel_name?: string | null;
    carrier_code?: string | null;
    carrier_name?: string | null;
    aircraft_code?: string | null;
    flight_no?: string | null;
    emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
    load_factor?: number | null;
    load_type?: EmissionFreightLoadTypeEnum | null;
    empty_running?: number | null;
    cargo_type: EmissionFreightCargoTypeEnum;
    is_refrigerated: boolean;
    from_address?: string | null;
    from_code?: string | null;
    from_country_code?: string | null;
    from_country_name?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_code?: string | null;
    to_country_code?: string | null;
    to_display?: string | null;
    route_details?: {
      __typename?: 'RouteDetails';
      country_code_alpha_2?: string | null;
      is_ferry?: boolean | null;
      is_train?: boolean | null;
      stops?: Array<{
        __typename?: 'RouteStop';
        code?: string | null;
        stop_info?: { __typename?: 'RouteStopInfo'; code_alpha_2?: string | null; name?: string | null } | null;
      }> | null;
    } | null;
    fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  } | null;
  details: Array<{
    __typename?: 'EmissionDetail';
    co2e: number;
    co2?: number | null;
    ch4?: number | null;
    n2o?: number | null;
    nox?: number | null;
    so2?: number | null;
    pm?: number | null;
    nmhc?: number | null;
    type?: EmissionDetailTypeEnum | null;
    subtype?: EmissionDetailSubtypeEnum | null;
    scope?: EmissionDetailScopeEnum | null;
    category?: EmissionDetailCategoryEnum | null;
  }>;
};

export type EmissionDetail_FromGetEmissionFragment = {
  __typename?: 'EmissionDetail';
  co2e: number;
  co2?: number | null;
  ch4?: number | null;
  n2o?: number | null;
  nox?: number | null;
  so2?: number | null;
  pm?: number | null;
  nmhc?: number | null;
  type?: EmissionDetailTypeEnum | null;
  subtype?: EmissionDetailSubtypeEnum | null;
  scope?: EmissionDetailScopeEnum | null;
  category?: EmissionDetailCategoryEnum | null;
};

export type EmissionIssue_FromGetEmissionFragment = {
  __typename?: 'EmissionIssue';
  param: string;
  input_value?: string | null;
  assumed_value?: string | null;
  recommendations: Array<{
    __typename?: 'EmissionIssueRecommendation';
    action: RecommendationAction;
    params: Array<string>;
  }>;
};

export type EmissionParentQuality_FromGetEmissionFragment = {
  __typename?: 'EmissionParentQuality';
  overall?: number | null;
};

export type EmissionQuality_FromGetEmissionFragment = {
  __typename?: 'EmissionQuality';
  overall?: EmissionQualityScoreEnum | null;
  origin_destination?: EmissionQualityScoreEnum | null;
  weight?: EmissionQualityScoreEnum | null;
  emission_intensity_factor?: EmissionQualityScoreEnum | null;
};

export type GetPublicEmissionQueryVariables = Exact<{
  share_token: Scalars['String']['input'];
}>;

export type GetPublicEmissionQuery = {
  __typename?: 'Query';
  public_emission: {
    __typename?: 'Emission';
    id: string;
    public_id: string;
    import_id?: string | null;
    type: EmissionTypeEnum;
    co2e: number;
    intensity_normalized?: number | null;
    activity_normalized?: number | null;
    activity_normalized_unit?: EmissionFactorUnitEnum | null;
    date: string;
    created_date: string;
    distance_km?: number | null;
    sequence_no?: number | null;
    mode: EmissionModeEnum;
    weight_kg?: number | null;
    teu?: number | null;
    no_of_pax?: number | null;
    context_stringify?: string | null;
    source_type_name: EmissionSourceTypeEnum;
    has_parent: boolean;
    parent_id?: string | null;
    parent_public_id?: string | null;
    parent_child_count?: number | null;
    parent_share_token?: string | null;
    share_token: string;
    shipment_reference_number_metadata_key: string;
    shipment_reference_number_metadata_value?: string | null;
    client: {
      __typename?: 'Client';
      legal_name?: string | null;
      id: string;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
      branding?: { __typename?: 'ClientBranding'; brandColour: string; accentColour: string } | null;
    };
    meta_data?: Array<{ __typename?: 'Metadata'; key: string; display: string; value: string }> | null;
    quality?: {
      __typename?: 'EmissionQuality';
      overall?: EmissionQualityScoreEnum | null;
      origin_destination?: EmissionQualityScoreEnum | null;
      weight?: EmissionQualityScoreEnum | null;
      emission_intensity_factor?: EmissionQualityScoreEnum | null;
    } | null;
    parent_quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
    emission_children: Array<{
      __typename?: 'Emission';
      id: string;
      public_id: string;
      type: EmissionTypeEnum;
      mode: EmissionModeEnum;
      co2e: number;
      date: string;
      distance_km?: number | null;
      weight_kg?: number | null;
      teu?: number | null;
      context_stringify?: string | null;
      share_token: string;
      sequence_no?: number | null;
      quality?: {
        __typename?: 'EmissionQuality';
        overall?: EmissionQualityScoreEnum | null;
        origin_destination?: EmissionQualityScoreEnum | null;
        weight?: EmissionQualityScoreEnum | null;
        emission_intensity_factor?: EmissionQualityScoreEnum | null;
      } | null;
      issues?: Array<{
        __typename?: 'EmissionIssue';
        param: string;
        input_value?: string | null;
        assumed_value?: string | null;
        recommendations: Array<{
          __typename?: 'EmissionIssueRecommendation';
          action: RecommendationAction;
          params: Array<string>;
        }>;
      }> | null;
      freight?: {
        __typename?: 'EmissionFreight';
        carriage: EmissionFreightCarriageEnum;
        vehicle: EmissionFreightVehicleEnum;
        vehicle_code?: string | null;
        vehicle_category: EmissionFreightVehicleCategoryEnum;
        fuel_type?: EmissionFreightFuelTypeEnum | null;
        fuel_consumption?: number | null;
        fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
        vessel_id?: string | null;
        vessel_name?: string | null;
        carrier_code?: string | null;
        carrier_name?: string | null;
        aircraft_code?: string | null;
        flight_no?: string | null;
        emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
        load_factor?: number | null;
        load_type?: EmissionFreightLoadTypeEnum | null;
        empty_running?: number | null;
        cargo_type: EmissionFreightCargoTypeEnum;
        is_refrigerated: boolean;
        from_address?: string | null;
        from_code?: string | null;
        from_country_code?: string | null;
        from_country_name?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_code?: string | null;
        to_country_code?: string | null;
        to_display?: string | null;
        route_details?: {
          __typename?: 'RouteDetails';
          is_urban?: boolean | null;
          is_motorway?: boolean | null;
          is_ferry?: boolean | null;
          is_train?: boolean | null;
          country_code_alpha_2?: string | null;
        } | null;
        fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      freight_aggregated?: {
        __typename?: 'EmissionFreightAggregated';
        start_date: string;
        end_date: string;
        vehicle: EmissionFreightAggregatedVehicleEnum;
        vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
        vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
        fuel_type: EmissionFreightAggregatedFuelTypeEnum;
        fuel_consumption: number;
        fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
        fuel_used: number;
        fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
        load_factor: number;
        distance_km: number;
        weight_kg: number;
        vehicle_id?: string | null;
        fleet_id?: string | null;
      } | null;
      flights?: {
        __typename?: 'EmissionFlights';
        no_of_pax: number;
        cabin_class?: EmissionFlightsClassEnum | null;
        airline?: string | null;
        aircraft?: string | null;
        flight_no?: string | null;
        from_code: string;
        from_display?: string | null;
        to_code: string;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      last_mile?: {
        __typename?: 'EmissionLastMile';
        vehicle: EmissionLastMileVehicleEnum;
        vehicle_category: EmissionLastMileVehicleCategoryEnum;
        fuel_type?: EmissionLastMileFuelTypeEnum | null;
        make?: string | null;
        model?: string | null;
        from_address?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      ride_hailing?: {
        __typename?: 'EmissionRideHailing';
        vehicle: EmissionRideHailingVehicleEnum;
        vehicle_category: EmissionRideHailingVehicleCategoryEnum;
        fuel_type?: EmissionRideHailingFuelTypeEnum | null;
        make?: string | null;
        model?: string | null;
        no_of_pax?: number | null;
        from_address?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      details: Array<{
        __typename?: 'EmissionDetail';
        co2e: number;
        co2?: number | null;
        ch4?: number | null;
        n2o?: number | null;
        nox?: number | null;
        so2?: number | null;
        pm?: number | null;
        nmhc?: number | null;
        type?: EmissionDetailTypeEnum | null;
        subtype?: EmissionDetailSubtypeEnum | null;
        scope?: EmissionDetailScopeEnum | null;
        category?: EmissionDetailCategoryEnum | null;
      }>;
      emission_children: Array<{
        __typename?: 'Emission';
        id: string;
        public_id: string;
        type: EmissionTypeEnum;
        mode: EmissionModeEnum;
        co2e: number;
        date: string;
        distance_km?: number | null;
        weight_kg?: number | null;
        context_stringify?: string | null;
        sequence_no?: number | null;
        issues?: Array<{
          __typename?: 'EmissionIssue';
          param: string;
          input_value?: string | null;
          assumed_value?: string | null;
          recommendations: Array<{
            __typename?: 'EmissionIssueRecommendation';
            action: RecommendationAction;
            params: Array<string>;
          }>;
        }> | null;
        freight?: {
          __typename?: 'EmissionFreight';
          carriage: EmissionFreightCarriageEnum;
          vehicle: EmissionFreightVehicleEnum;
          vehicle_code?: string | null;
          vehicle_category: EmissionFreightVehicleCategoryEnum;
          fuel_type?: EmissionFreightFuelTypeEnum | null;
          fuel_consumption?: number | null;
          fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
          vessel_id?: string | null;
          vessel_name?: string | null;
          carrier_code?: string | null;
          carrier_name?: string | null;
          aircraft_code?: string | null;
          flight_no?: string | null;
          emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
          load_factor?: number | null;
          load_type?: EmissionFreightLoadTypeEnum | null;
          empty_running?: number | null;
          cargo_type: EmissionFreightCargoTypeEnum;
          is_refrigerated: boolean;
          from_address?: string | null;
          from_code?: string | null;
          from_country_code?: string | null;
          from_country_name?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_code?: string | null;
          to_country_code?: string | null;
          to_display?: string | null;
          route_details?: {
            __typename?: 'RouteDetails';
            is_urban?: boolean | null;
            is_motorway?: boolean | null;
            is_ferry?: boolean | null;
            is_train?: boolean | null;
            country_code_alpha_2?: string | null;
          } | null;
          fuel_identifier?: {
            __typename?: 'EmissionFreightFuelIdentifier';
            code: string;
            source?: string | null;
          } | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        } | null;
        freight_aggregated?: {
          __typename?: 'EmissionFreightAggregated';
          start_date: string;
          end_date: string;
          vehicle: EmissionFreightAggregatedVehicleEnum;
          vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
          vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
          fuel_type: EmissionFreightAggregatedFuelTypeEnum;
          fuel_consumption: number;
          fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
          fuel_used: number;
          fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
          load_factor: number;
          distance_km: number;
          weight_kg: number;
          vehicle_id?: string | null;
          fleet_id?: string | null;
        } | null;
        flights?: {
          __typename?: 'EmissionFlights';
          no_of_pax: number;
          cabin_class?: EmissionFlightsClassEnum | null;
          airline?: string | null;
          aircraft?: string | null;
          flight_no?: string | null;
          from_code: string;
          from_display?: string | null;
          to_code: string;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        } | null;
        last_mile?: {
          __typename?: 'EmissionLastMile';
          vehicle: EmissionLastMileVehicleEnum;
          vehicle_category: EmissionLastMileVehicleCategoryEnum;
          fuel_type?: EmissionLastMileFuelTypeEnum | null;
          make?: string | null;
          model?: string | null;
          from_address?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        } | null;
        ride_hailing?: {
          __typename?: 'EmissionRideHailing';
          vehicle: EmissionRideHailingVehicleEnum;
          vehicle_category: EmissionRideHailingVehicleCategoryEnum;
          fuel_type?: EmissionRideHailingFuelTypeEnum | null;
          make?: string | null;
          model?: string | null;
          no_of_pax?: number | null;
          from_address?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        } | null;
        details: Array<{
          __typename?: 'EmissionDetail';
          co2e: number;
          co2?: number | null;
          ch4?: number | null;
          n2o?: number | null;
          nox?: number | null;
          so2?: number | null;
          pm?: number | null;
          nmhc?: number | null;
          type?: EmissionDetailTypeEnum | null;
          subtype?: EmissionDetailSubtypeEnum | null;
          scope?: EmissionDetailScopeEnum | null;
          category?: EmissionDetailCategoryEnum | null;
        }>;
      }>;
    }>;
    last_mile?: {
      __typename?: 'EmissionLastMile';
      vehicle: EmissionLastMileVehicleEnum;
      vehicle_category: EmissionLastMileVehicleCategoryEnum;
      fuel_type?: EmissionLastMileFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    ride_hailing?: {
      __typename?: 'EmissionRideHailing';
      vehicle: EmissionRideHailingVehicleEnum;
      vehicle_category: EmissionRideHailingVehicleCategoryEnum;
      fuel_type?: EmissionRideHailingFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      no_of_pax?: number | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    flights?: {
      __typename?: 'EmissionFlights';
      no_of_pax: number;
      cabin_class?: EmissionFlightsClassEnum | null;
      airline?: string | null;
      aircraft?: string | null;
      flight_no?: string | null;
      from_code: string;
      from_display?: string | null;
      to_code: string;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    freight?: {
      __typename?: 'EmissionFreight';
      carriage: EmissionFreightCarriageEnum;
      vehicle: EmissionFreightVehicleEnum;
      vehicle_code?: string | null;
      vehicle_category: EmissionFreightVehicleCategoryEnum;
      fuel_type?: EmissionFreightFuelTypeEnum | null;
      fuel_consumption?: number | null;
      fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
      vessel_id?: string | null;
      vessel_name?: string | null;
      carrier_code?: string | null;
      carrier_name?: string | null;
      aircraft_code?: string | null;
      flight_no?: string | null;
      emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
      load_factor?: number | null;
      load_type?: EmissionFreightLoadTypeEnum | null;
      empty_running?: number | null;
      cargo_type: EmissionFreightCargoTypeEnum;
      is_refrigerated: boolean;
      from_address?: string | null;
      from_code?: string | null;
      from_country_code?: string | null;
      from_country_name?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_code?: string | null;
      to_country_code?: string | null;
      to_display?: string | null;
      route_details?: {
        __typename?: 'RouteDetails';
        country_code_alpha_2?: string | null;
        is_ferry?: boolean | null;
        is_train?: boolean | null;
        stops?: Array<{
          __typename?: 'RouteStop';
          code?: string | null;
          stop_info?: { __typename?: 'RouteStopInfo'; code_alpha_2?: string | null; name?: string | null } | null;
        }> | null;
      } | null;
      fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    details: Array<{
      __typename?: 'EmissionDetail';
      co2e: number;
      co2?: number | null;
      ch4?: number | null;
      n2o?: number | null;
      nox?: number | null;
      so2?: number | null;
      pm?: number | null;
      nmhc?: number | null;
      type?: EmissionDetailTypeEnum | null;
      subtype?: EmissionDetailSubtypeEnum | null;
      scope?: EmissionDetailScopeEnum | null;
      category?: EmissionDetailCategoryEnum | null;
    }>;
  };
};

export type OffsettingLinkFieldsFragment = {
  __typename?: 'OffsettingLink';
  id: string;
  public_id: string;
  type: OffsettingLinkTypeEnum;
  is_active: boolean;
  portfolios_public_ids: Array<string>;
  currency: CurrencyEnum;
  show_confirmation_page: boolean;
  redirect_url?: string | null;
  is_default: boolean;
  created_date: string;
  updated_date: string;
};

export type PortfoliosFieldsFragment = {
  __typename?: 'OffsettingLinkPortfolio';
  public_id: string;
  name: string;
  description: string;
  portfolio_version: string;
  available_tonnes: number;
  is_sold_out: boolean;
  prices: Array<{ __typename?: 'OffsettingLinkPortfolioPrices'; currency: string; price_per_kg: number }>;
  media?: Array<{ __typename?: 'S3Object'; key: string; bucket: string }> | null;
  allocations: Array<{
    __typename?: 'PortfolioAllocation';
    percentage: number;
    project: {
      __typename?: 'Project';
      public_id: string;
      name: string;
      avoidance: boolean;
      removal: boolean;
      location?: string | null;
      sdgs?: Array<SustainableDevelopmentGoalsEnum> | null;
      website?: string | null;
      coordinates?: { __typename?: 'Geography'; type: string; coordinates: Array<number> } | null;
      country: { __typename?: 'Country'; name: string; code: string };
      project_type: { __typename?: 'ProjectType'; name: string; category: string };
      media?: Array<{ __typename?: 'S3Object'; key: string; bucket: string }> | null;
    };
  }>;
};

export type ClientFieldsFragment = {
  __typename?: 'Client';
  legal_name?: string | null;
  offsetting_commission_percentage: number;
  logo?: { __typename?: 'S3Object'; key: string; bucket: string } | null;
  branding?: { __typename?: 'ClientBranding'; brandColour: string; accentColour: string } | null;
};

export type EmissionFieldsFragment = {
  __typename?: 'Emission';
  id: string;
  co2e: number;
  distance_km?: number | null;
  weight_kg?: number | null;
  from_display?: string | null;
  to_display?: string | null;
  type: EmissionTypeEnum;
  date: string;
  no_of_pax?: number | null;
  shipment_reference_number_metadata_value?: string | null;
};

export type GetPublicOffsettingLinkQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
  emission_id?: InputMaybe<Scalars['String']['input']>;
  include_emission: Scalars['Boolean']['input'];
}>;

export type GetPublicOffsettingLinkQuery = {
  __typename?: 'Query';
  public_offsetting_link: {
    __typename?: 'GetOffsettingLink';
    offsetting_link: {
      __typename?: 'OffsettingLink';
      id: string;
      public_id: string;
      type: OffsettingLinkTypeEnum;
      is_active: boolean;
      portfolios_public_ids: Array<string>;
      currency: CurrencyEnum;
      show_confirmation_page: boolean;
      redirect_url?: string | null;
      is_default: boolean;
      created_date: string;
      updated_date: string;
    };
    portfolios: Array<{
      __typename?: 'OffsettingLinkPortfolio';
      public_id: string;
      name: string;
      description: string;
      portfolio_version: string;
      available_tonnes: number;
      is_sold_out: boolean;
      prices: Array<{ __typename?: 'OffsettingLinkPortfolioPrices'; currency: string; price_per_kg: number }>;
      media?: Array<{ __typename?: 'S3Object'; key: string; bucket: string }> | null;
      allocations: Array<{
        __typename?: 'PortfolioAllocation';
        percentage: number;
        project: {
          __typename?: 'Project';
          public_id: string;
          name: string;
          avoidance: boolean;
          removal: boolean;
          location?: string | null;
          sdgs?: Array<SustainableDevelopmentGoalsEnum> | null;
          website?: string | null;
          coordinates?: { __typename?: 'Geography'; type: string; coordinates: Array<number> } | null;
          country: { __typename?: 'Country'; name: string; code: string };
          project_type: { __typename?: 'ProjectType'; name: string; category: string };
          media?: Array<{ __typename?: 'S3Object'; key: string; bucket: string }> | null;
        };
      }>;
    }>;
    client: {
      __typename?: 'Client';
      legal_name?: string | null;
      offsetting_commission_percentage: number;
      logo?: { __typename?: 'S3Object'; key: string; bucket: string } | null;
      branding?: { __typename?: 'ClientBranding'; brandColour: string; accentColour: string } | null;
    };
    emission?: {
      __typename?: 'Emission';
      id: string;
      co2e: number;
      distance_km?: number | null;
      weight_kg?: number | null;
      from_display?: string | null;
      to_display?: string | null;
      type: EmissionTypeEnum;
      date: string;
      no_of_pax?: number | null;
      shipment_reference_number_metadata_value?: string | null;
    } | null;
  };
};

export type GhgTypeBreakdownItemFragment = {
  __typename?: 'TypeBreakdownItem';
  label: string;
  co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
  biogenic: { __typename?: 'BiogenicBreakdown'; co2: { __typename?: 'EmissionNumber'; value: number; unit: string } };
  methodology: {
    __typename?: 'GhgMethodology';
    sources: Array<{
      __typename?: 'GhgSource';
      name: string;
      year?: number | null;
      version?: string | null;
      revision?: string | null;
    }>;
    geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
    routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
    methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
  };
};

export type GhgBreakdownItemFragment = {
  __typename?: 'GhgBreakdownItem';
  label: string;
  co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
  ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
};

export type GhgResultsFieldsFragment = {
  __typename?: 'GhgReportResults';
  total_co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
  scope_breakdown: Array<{
    __typename?: 'ScopeBreakdown';
    label: string;
    co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
  }>;
  type_breakdown: {
    __typename?: 'TypeBreakdown';
    scope_1?: Array<{
      __typename?: 'TypeBreakdownItem';
      label: string;
      co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      biogenic: {
        __typename?: 'BiogenicBreakdown';
        co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
      };
      methodology: {
        __typename?: 'GhgMethodology';
        sources: Array<{
          __typename?: 'GhgSource';
          name: string;
          year?: number | null;
          version?: string | null;
          revision?: string | null;
        }>;
        geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
        routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
        methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
      };
    }> | null;
    scope_2?: Array<{
      __typename?: 'TypeBreakdownItem';
      label: string;
      co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      biogenic: {
        __typename?: 'BiogenicBreakdown';
        co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
      };
      methodology: {
        __typename?: 'GhgMethodology';
        sources: Array<{
          __typename?: 'GhgSource';
          name: string;
          year?: number | null;
          version?: string | null;
          revision?: string | null;
        }>;
        geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
        routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
        methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
      };
    }> | null;
    scope_3?: Array<{
      __typename?: 'TypeBreakdownItem';
      label: string;
      co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      biogenic: {
        __typename?: 'BiogenicBreakdown';
        co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
      };
      methodology: {
        __typename?: 'GhgMethodology';
        sources: Array<{
          __typename?: 'GhgSource';
          name: string;
          year?: number | null;
          version?: string | null;
          revision?: string | null;
        }>;
        geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
        routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
        methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
      };
    }> | null;
  };
  ghg_breakdown: {
    __typename?: 'GhgBreakdown';
    scope_1?: Array<{
      __typename?: 'GhgBreakdownItem';
      label: string;
      co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
    }> | null;
    scope_2?: Array<{
      __typename?: 'GhgBreakdownItem';
      label: string;
      co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
    }> | null;
    scope_3?: Array<{
      __typename?: 'GhgBreakdownItem';
      label: string;
      co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
    }> | null;
  };
};

export type GetPublicGhgReportQueryVariables = Exact<{
  share_token: Scalars['String']['input'];
}>;

export type GetPublicGhgReportQuery = {
  __typename?: 'Query';
  public_ghg_report: {
    __typename?: 'GhgReport';
    id: string;
    name: string;
    prepared_for?: string | null;
    created_date: string;
    type: ReportTypeEnum;
    is_branded?: boolean | null;
    filters: {
      __typename?: 'GhgReportFilters';
      start_date?: string | null;
      end_date?: string | null;
      import_id?: string | null;
      client_identifier?: string | null;
    };
    results: {
      __typename?: 'GhgReportResults';
      total_co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      scope_breakdown: Array<{
        __typename?: 'ScopeBreakdown';
        label: string;
        co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      }>;
      type_breakdown: {
        __typename?: 'TypeBreakdown';
        scope_1?: Array<{
          __typename?: 'TypeBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          biogenic: {
            __typename?: 'BiogenicBreakdown';
            co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
          };
          methodology: {
            __typename?: 'GhgMethodology';
            sources: Array<{
              __typename?: 'GhgSource';
              name: string;
              year?: number | null;
              version?: string | null;
              revision?: string | null;
            }>;
            geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
          };
        }> | null;
        scope_2?: Array<{
          __typename?: 'TypeBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          biogenic: {
            __typename?: 'BiogenicBreakdown';
            co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
          };
          methodology: {
            __typename?: 'GhgMethodology';
            sources: Array<{
              __typename?: 'GhgSource';
              name: string;
              year?: number | null;
              version?: string | null;
              revision?: string | null;
            }>;
            geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
          };
        }> | null;
        scope_3?: Array<{
          __typename?: 'TypeBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          biogenic: {
            __typename?: 'BiogenicBreakdown';
            co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
          };
          methodology: {
            __typename?: 'GhgMethodology';
            sources: Array<{
              __typename?: 'GhgSource';
              name: string;
              year?: number | null;
              version?: string | null;
              revision?: string | null;
            }>;
            geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
          };
        }> | null;
      };
      ghg_breakdown: {
        __typename?: 'GhgBreakdown';
        scope_1?: Array<{
          __typename?: 'GhgBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
        }> | null;
        scope_2?: Array<{
          __typename?: 'GhgBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
        }> | null;
        scope_3?: Array<{
          __typename?: 'GhgBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
        }> | null;
      };
    };
    client: {
      __typename?: 'Client';
      legal_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
      branding?: { __typename?: 'ClientBranding'; brandColour: string; accentColour: string } | null;
    };
    client_user?: {
      __typename?: 'ClientUser';
      first_name?: string | null;
      last_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    } | null;
  };
};

export type GlecBreakdownFieldsFragment = {
  __typename?: 'GlecReportBreakdown';
  label: string;
  percentage: number;
  co2e: number;
  activity?: number | null;
  activity_unit?: string | null;
  intensity?: number | null;
  intensity_unit?: string | null;
};

export type GlecReportByModeFieldsFragment = {
  __typename?: 'GlecReportByMode';
  mode: EmissionModeEnum;
  main_data_type?: string | null;
  items: Array<{
    __typename?: 'GlecReportByModeItem';
    co2e: number;
    activity?: number | null;
    activity_unit?: string | null;
    intensity?: number | null;
    intensity_unit?: string | null;
  }>;
};

export type GlecResultsFieldsFragment = {
  __typename?: 'GlecReportResults';
  total_co2e: number;
  total_activity?: number | null;
  total_activity_unit?: string | null;
  total_intensity?: number | null;
  total_intensity_unit?: string | null;
  breakdown_by_type: Array<{
    __typename?: 'GlecReportBreakdown';
    label: string;
    percentage: number;
    co2e: number;
    activity?: number | null;
    activity_unit?: string | null;
    intensity?: number | null;
    intensity_unit?: string | null;
  }>;
  breakdown_by_scope: Array<{
    __typename?: 'GlecReportBreakdown';
    label: string;
    percentage: number;
    co2e: number;
    activity?: number | null;
    activity_unit?: string | null;
    intensity?: number | null;
    intensity_unit?: string | null;
  }>;
  breakdown_by_mode: Array<{
    __typename?: 'GlecReportBreakdown';
    label: string;
    percentage: number;
    co2e: number;
    activity?: number | null;
    activity_unit?: string | null;
    intensity?: number | null;
    intensity_unit?: string | null;
  }>;
  breakdown_by_carriage: Array<{
    __typename?: 'GlecReportBreakdown';
    label: string;
    percentage: number;
    co2e: number;
    activity?: number | null;
    activity_unit?: string | null;
    intensity?: number | null;
    intensity_unit?: string | null;
  }>;
  breakdown_by_data_type: Array<{ __typename?: 'GlecReportBreakdown'; label: string; percentage: number }>;
  report_by_mode: Array<{
    __typename?: 'GlecReportByMode';
    mode: EmissionModeEnum;
    main_data_type?: string | null;
    items: Array<{
      __typename?: 'GlecReportByModeItem';
      co2e: number;
      activity?: number | null;
      activity_unit?: string | null;
      intensity?: number | null;
      intensity_unit?: string | null;
    }>;
  }>;
};

export type GetPublicGlecReportQueryVariables = Exact<{
  share_token: Scalars['String']['input'];
}>;

export type GetPublicGlecReportQuery = {
  __typename?: 'Query';
  public_glec_report: {
    __typename?: 'GlecReport';
    id: string;
    public_id: string;
    name: string;
    prepared_for?: string | null;
    type: ReportTypeEnum;
    visibility: ReportVisibilityEnum;
    version_number: number;
    share_token: string;
    created_date: string;
    is_branded?: boolean | null;
    filters: {
      __typename?: 'GlecReportFilters';
      start_date?: string | null;
      end_date?: string | null;
      import_id?: string | null;
      client_identifier?: string | null;
      supply_chain_coverage: string;
      confirmed: boolean;
      confirmed_text?: string | null;
    };
    results: {
      __typename?: 'GlecReportResults';
      total_co2e: number;
      total_activity?: number | null;
      total_activity_unit?: string | null;
      total_intensity?: number | null;
      total_intensity_unit?: string | null;
      breakdown_by_type: Array<{
        __typename?: 'GlecReportBreakdown';
        label: string;
        percentage: number;
        co2e: number;
        activity?: number | null;
        activity_unit?: string | null;
        intensity?: number | null;
        intensity_unit?: string | null;
      }>;
      breakdown_by_scope: Array<{
        __typename?: 'GlecReportBreakdown';
        label: string;
        percentage: number;
        co2e: number;
        activity?: number | null;
        activity_unit?: string | null;
        intensity?: number | null;
        intensity_unit?: string | null;
      }>;
      breakdown_by_mode: Array<{
        __typename?: 'GlecReportBreakdown';
        label: string;
        percentage: number;
        co2e: number;
        activity?: number | null;
        activity_unit?: string | null;
        intensity?: number | null;
        intensity_unit?: string | null;
      }>;
      breakdown_by_carriage: Array<{
        __typename?: 'GlecReportBreakdown';
        label: string;
        percentage: number;
        co2e: number;
        activity?: number | null;
        activity_unit?: string | null;
        intensity?: number | null;
        intensity_unit?: string | null;
      }>;
      breakdown_by_data_type: Array<{ __typename?: 'GlecReportBreakdown'; label: string; percentage: number }>;
      report_by_mode: Array<{
        __typename?: 'GlecReportByMode';
        mode: EmissionModeEnum;
        main_data_type?: string | null;
        items: Array<{
          __typename?: 'GlecReportByModeItem';
          co2e: number;
          activity?: number | null;
          activity_unit?: string | null;
          intensity?: number | null;
          intensity_unit?: string | null;
        }>;
      }>;
    };
    client: {
      __typename?: 'Client';
      legal_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
      branding?: { __typename?: 'ClientBranding'; brandColour: string; accentColour: string } | null;
    };
    client_user?: {
      __typename?: 'ClientUser';
      first_name?: string | null;
      last_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    } | null;
  };
};

export type ItemisedEmissionsResultsFieldsFragment = {
  __typename?: 'ItemisedEmissionsReportResults';
  total_shipment: number;
  total_shipment_leg: number;
  total_co2e: number;
  total_activity: number;
  total_activity_unit: string;
  total_intensity: number;
  total_intensity_unit: string;
  overall_quality: number;
  breakdown_by_type: Array<{
    __typename?: 'ItemisedEmissionsReportBreakdownByType';
    label: EmissionDetailTypeEnum;
    percentage?: number | null;
    co2e: number;
  }>;
  breakdown_by_shipment: Array<{
    __typename?: 'ItemisedEmissionsReportBreakdownByShipment';
    id: string;
    shipment_identifier?: string | null;
    mode: EmissionModeEnum;
    date: string;
    from_address?: string | null;
    from_display?: string | null;
    from_code?: string | null;
    to_address?: string | null;
    to_display?: string | null;
    to_code?: string | null;
    distance_km?: number | null;
    weight_kg: number;
    teu?: number | null;
    co2e: number;
    activity: number;
    activity_unit: string;
    intensity: number;
    intensity_unit: string;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
    breakdown_by_type: Array<{
      __typename?: 'ItemisedEmissionsReportBreakdownByType';
      label: EmissionDetailTypeEnum;
      co2e: number;
    }>;
  }>;
};

export type GetPublicItemisedEmissionsReportQueryVariables = Exact<{
  share_token: Scalars['String']['input'];
}>;

export type GetPublicItemisedEmissionsReportQuery = {
  __typename?: 'Query';
  public_itemised_emissions_report: {
    __typename?: 'ItemisedEmissionsReport';
    id: string;
    public_id: string;
    name: string;
    prepared_for?: string | null;
    type: ReportTypeEnum;
    visibility: ReportVisibilityEnum;
    version_number: number;
    share_token: string;
    created_date: string;
    is_branded?: boolean | null;
    filters: {
      __typename?: 'ItemisedEmissionsReportFilters';
      start_date?: string | null;
      end_date?: string | null;
      import_id?: string | null;
      client_identifier: string;
      shipment_identifier: string;
    };
    results: {
      __typename?: 'ItemisedEmissionsReportResults';
      total_shipment: number;
      total_shipment_leg: number;
      total_co2e: number;
      total_activity: number;
      total_activity_unit: string;
      total_intensity: number;
      total_intensity_unit: string;
      overall_quality: number;
      breakdown_by_type: Array<{
        __typename?: 'ItemisedEmissionsReportBreakdownByType';
        label: EmissionDetailTypeEnum;
        percentage?: number | null;
        co2e: number;
      }>;
      breakdown_by_shipment: Array<{
        __typename?: 'ItemisedEmissionsReportBreakdownByShipment';
        id: string;
        shipment_identifier?: string | null;
        mode: EmissionModeEnum;
        date: string;
        from_address?: string | null;
        from_display?: string | null;
        from_code?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        to_code?: string | null;
        distance_km?: number | null;
        weight_kg: number;
        teu?: number | null;
        co2e: number;
        activity: number;
        activity_unit: string;
        intensity: number;
        intensity_unit: string;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
        breakdown_by_type: Array<{
          __typename?: 'ItemisedEmissionsReportBreakdownByType';
          label: EmissionDetailTypeEnum;
          co2e: number;
        }>;
      }>;
    };
    client: {
      __typename?: 'Client';
      legal_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
      branding?: { __typename?: 'ClientBranding'; brandColour: string; accentColour: string } | null;
    };
    client_user?: {
      __typename?: 'ClientUser';
      first_name?: string | null;
      last_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    } | null;
  };
};

export type GetPublicReportQueryVariables = Exact<{
  share_token: Scalars['String']['input'];
}>;

export type GetPublicReportQuery = {
  __typename?: 'Query';
  public_report: { __typename?: 'Report'; id: string; type: ReportTypeEnum };
};

export type GetPublicUntrackedEmissionQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type GetPublicUntrackedEmissionQuery = {
  __typename?: 'Query';
  public_untracked_emission: {
    __typename?: 'Emission';
    id: string;
    public_id: string;
    import_id?: string | null;
    type: EmissionTypeEnum;
    co2e: number;
    intensity_normalized?: number | null;
    activity_normalized?: number | null;
    activity_normalized_unit?: EmissionFactorUnitEnum | null;
    date: string;
    created_date: string;
    distance_km?: number | null;
    sequence_no?: number | null;
    mode: EmissionModeEnum;
    weight_kg?: number | null;
    teu?: number | null;
    no_of_pax?: number | null;
    context_stringify?: string | null;
    source_type_name: EmissionSourceTypeEnum;
    has_parent: boolean;
    parent_id?: string | null;
    parent_public_id?: string | null;
    parent_child_count?: number | null;
    parent_share_token?: string | null;
    share_token: string;
    shipment_reference_number_metadata_key: string;
    shipment_reference_number_metadata_value?: string | null;
    client: {
      __typename?: 'Client';
      legal_name?: string | null;
      id: string;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
      branding?: { __typename?: 'ClientBranding'; brandColour: string; accentColour: string } | null;
    };
    meta_data?: Array<{ __typename?: 'Metadata'; key: string; display: string; value: string }> | null;
    quality?: {
      __typename?: 'EmissionQuality';
      overall?: EmissionQualityScoreEnum | null;
      origin_destination?: EmissionQualityScoreEnum | null;
      weight?: EmissionQualityScoreEnum | null;
      emission_intensity_factor?: EmissionQualityScoreEnum | null;
    } | null;
    parent_quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
    emission_children: Array<{
      __typename?: 'Emission';
      id: string;
      public_id: string;
      type: EmissionTypeEnum;
      mode: EmissionModeEnum;
      co2e: number;
      date: string;
      distance_km?: number | null;
      weight_kg?: number | null;
      teu?: number | null;
      context_stringify?: string | null;
      share_token: string;
      sequence_no?: number | null;
      quality?: {
        __typename?: 'EmissionQuality';
        overall?: EmissionQualityScoreEnum | null;
        origin_destination?: EmissionQualityScoreEnum | null;
        weight?: EmissionQualityScoreEnum | null;
        emission_intensity_factor?: EmissionQualityScoreEnum | null;
      } | null;
      issues?: Array<{
        __typename?: 'EmissionIssue';
        param: string;
        input_value?: string | null;
        assumed_value?: string | null;
        recommendations: Array<{
          __typename?: 'EmissionIssueRecommendation';
          action: RecommendationAction;
          params: Array<string>;
        }>;
      }> | null;
      freight?: {
        __typename?: 'EmissionFreight';
        carriage: EmissionFreightCarriageEnum;
        vehicle: EmissionFreightVehicleEnum;
        vehicle_code?: string | null;
        vehicle_category: EmissionFreightVehicleCategoryEnum;
        fuel_type?: EmissionFreightFuelTypeEnum | null;
        fuel_consumption?: number | null;
        fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
        vessel_id?: string | null;
        vessel_name?: string | null;
        carrier_code?: string | null;
        carrier_name?: string | null;
        aircraft_code?: string | null;
        flight_no?: string | null;
        emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
        load_factor?: number | null;
        load_type?: EmissionFreightLoadTypeEnum | null;
        empty_running?: number | null;
        cargo_type: EmissionFreightCargoTypeEnum;
        is_refrigerated: boolean;
        from_address?: string | null;
        from_code?: string | null;
        from_country_code?: string | null;
        from_country_name?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_code?: string | null;
        to_country_code?: string | null;
        to_display?: string | null;
        route_details?: {
          __typename?: 'RouteDetails';
          is_urban?: boolean | null;
          is_motorway?: boolean | null;
          is_ferry?: boolean | null;
          is_train?: boolean | null;
          country_code_alpha_2?: string | null;
        } | null;
        fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      freight_aggregated?: {
        __typename?: 'EmissionFreightAggregated';
        start_date: string;
        end_date: string;
        vehicle: EmissionFreightAggregatedVehicleEnum;
        vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
        vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
        fuel_type: EmissionFreightAggregatedFuelTypeEnum;
        fuel_consumption: number;
        fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
        fuel_used: number;
        fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
        load_factor: number;
        distance_km: number;
        weight_kg: number;
        vehicle_id?: string | null;
        fleet_id?: string | null;
      } | null;
      flights?: {
        __typename?: 'EmissionFlights';
        no_of_pax: number;
        cabin_class?: EmissionFlightsClassEnum | null;
        airline?: string | null;
        aircraft?: string | null;
        flight_no?: string | null;
        from_code: string;
        from_display?: string | null;
        to_code: string;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      last_mile?: {
        __typename?: 'EmissionLastMile';
        vehicle: EmissionLastMileVehicleEnum;
        vehicle_category: EmissionLastMileVehicleCategoryEnum;
        fuel_type?: EmissionLastMileFuelTypeEnum | null;
        make?: string | null;
        model?: string | null;
        from_address?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      ride_hailing?: {
        __typename?: 'EmissionRideHailing';
        vehicle: EmissionRideHailingVehicleEnum;
        vehicle_category: EmissionRideHailingVehicleCategoryEnum;
        fuel_type?: EmissionRideHailingFuelTypeEnum | null;
        make?: string | null;
        model?: string | null;
        no_of_pax?: number | null;
        from_address?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      details: Array<{
        __typename?: 'EmissionDetail';
        co2e: number;
        co2?: number | null;
        ch4?: number | null;
        n2o?: number | null;
        nox?: number | null;
        so2?: number | null;
        pm?: number | null;
        nmhc?: number | null;
        type?: EmissionDetailTypeEnum | null;
        subtype?: EmissionDetailSubtypeEnum | null;
        scope?: EmissionDetailScopeEnum | null;
        category?: EmissionDetailCategoryEnum | null;
      }>;
      emission_children: Array<{
        __typename?: 'Emission';
        id: string;
        public_id: string;
        type: EmissionTypeEnum;
        mode: EmissionModeEnum;
        co2e: number;
        date: string;
        distance_km?: number | null;
        weight_kg?: number | null;
        context_stringify?: string | null;
        sequence_no?: number | null;
        issues?: Array<{
          __typename?: 'EmissionIssue';
          param: string;
          input_value?: string | null;
          assumed_value?: string | null;
          recommendations: Array<{
            __typename?: 'EmissionIssueRecommendation';
            action: RecommendationAction;
            params: Array<string>;
          }>;
        }> | null;
        freight?: {
          __typename?: 'EmissionFreight';
          carriage: EmissionFreightCarriageEnum;
          vehicle: EmissionFreightVehicleEnum;
          vehicle_code?: string | null;
          vehicle_category: EmissionFreightVehicleCategoryEnum;
          fuel_type?: EmissionFreightFuelTypeEnum | null;
          fuel_consumption?: number | null;
          fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
          vessel_id?: string | null;
          vessel_name?: string | null;
          carrier_code?: string | null;
          carrier_name?: string | null;
          aircraft_code?: string | null;
          flight_no?: string | null;
          emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
          load_factor?: number | null;
          load_type?: EmissionFreightLoadTypeEnum | null;
          empty_running?: number | null;
          cargo_type: EmissionFreightCargoTypeEnum;
          is_refrigerated: boolean;
          from_address?: string | null;
          from_code?: string | null;
          from_country_code?: string | null;
          from_country_name?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_code?: string | null;
          to_country_code?: string | null;
          to_display?: string | null;
          route_details?: {
            __typename?: 'RouteDetails';
            is_urban?: boolean | null;
            is_motorway?: boolean | null;
            is_ferry?: boolean | null;
            is_train?: boolean | null;
            country_code_alpha_2?: string | null;
          } | null;
          fuel_identifier?: {
            __typename?: 'EmissionFreightFuelIdentifier';
            code: string;
            source?: string | null;
          } | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        } | null;
        freight_aggregated?: {
          __typename?: 'EmissionFreightAggregated';
          start_date: string;
          end_date: string;
          vehicle: EmissionFreightAggregatedVehicleEnum;
          vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
          vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
          fuel_type: EmissionFreightAggregatedFuelTypeEnum;
          fuel_consumption: number;
          fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
          fuel_used: number;
          fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
          load_factor: number;
          distance_km: number;
          weight_kg: number;
          vehicle_id?: string | null;
          fleet_id?: string | null;
        } | null;
        flights?: {
          __typename?: 'EmissionFlights';
          no_of_pax: number;
          cabin_class?: EmissionFlightsClassEnum | null;
          airline?: string | null;
          aircraft?: string | null;
          flight_no?: string | null;
          from_code: string;
          from_display?: string | null;
          to_code: string;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        } | null;
        last_mile?: {
          __typename?: 'EmissionLastMile';
          vehicle: EmissionLastMileVehicleEnum;
          vehicle_category: EmissionLastMileVehicleCategoryEnum;
          fuel_type?: EmissionLastMileFuelTypeEnum | null;
          make?: string | null;
          model?: string | null;
          from_address?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        } | null;
        ride_hailing?: {
          __typename?: 'EmissionRideHailing';
          vehicle: EmissionRideHailingVehicleEnum;
          vehicle_category: EmissionRideHailingVehicleCategoryEnum;
          fuel_type?: EmissionRideHailingFuelTypeEnum | null;
          make?: string | null;
          model?: string | null;
          no_of_pax?: number | null;
          from_address?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        } | null;
        details: Array<{
          __typename?: 'EmissionDetail';
          co2e: number;
          co2?: number | null;
          ch4?: number | null;
          n2o?: number | null;
          nox?: number | null;
          so2?: number | null;
          pm?: number | null;
          nmhc?: number | null;
          type?: EmissionDetailTypeEnum | null;
          subtype?: EmissionDetailSubtypeEnum | null;
          scope?: EmissionDetailScopeEnum | null;
          category?: EmissionDetailCategoryEnum | null;
        }>;
      }>;
    }>;
    last_mile?: {
      __typename?: 'EmissionLastMile';
      vehicle: EmissionLastMileVehicleEnum;
      vehicle_category: EmissionLastMileVehicleCategoryEnum;
      fuel_type?: EmissionLastMileFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    ride_hailing?: {
      __typename?: 'EmissionRideHailing';
      vehicle: EmissionRideHailingVehicleEnum;
      vehicle_category: EmissionRideHailingVehicleCategoryEnum;
      fuel_type?: EmissionRideHailingFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      no_of_pax?: number | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    flights?: {
      __typename?: 'EmissionFlights';
      no_of_pax: number;
      cabin_class?: EmissionFlightsClassEnum | null;
      airline?: string | null;
      aircraft?: string | null;
      flight_no?: string | null;
      from_code: string;
      from_display?: string | null;
      to_code: string;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    freight?: {
      __typename?: 'EmissionFreight';
      carriage: EmissionFreightCarriageEnum;
      vehicle: EmissionFreightVehicleEnum;
      vehicle_code?: string | null;
      vehicle_category: EmissionFreightVehicleCategoryEnum;
      fuel_type?: EmissionFreightFuelTypeEnum | null;
      fuel_consumption?: number | null;
      fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
      vessel_id?: string | null;
      vessel_name?: string | null;
      carrier_code?: string | null;
      carrier_name?: string | null;
      aircraft_code?: string | null;
      flight_no?: string | null;
      emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
      load_factor?: number | null;
      load_type?: EmissionFreightLoadTypeEnum | null;
      empty_running?: number | null;
      cargo_type: EmissionFreightCargoTypeEnum;
      is_refrigerated: boolean;
      from_address?: string | null;
      from_code?: string | null;
      from_country_code?: string | null;
      from_country_name?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_code?: string | null;
      to_country_code?: string | null;
      to_display?: string | null;
      route_details?: {
        __typename?: 'RouteDetails';
        country_code_alpha_2?: string | null;
        is_ferry?: boolean | null;
        is_train?: boolean | null;
        stops?: Array<{
          __typename?: 'RouteStop';
          code?: string | null;
          stop_info?: { __typename?: 'RouteStopInfo'; code_alpha_2?: string | null; name?: string | null } | null;
        }> | null;
      } | null;
      fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    details: Array<{
      __typename?: 'EmissionDetail';
      co2e: number;
      co2?: number | null;
      ch4?: number | null;
      n2o?: number | null;
      nox?: number | null;
      so2?: number | null;
      pm?: number | null;
      nmhc?: number | null;
      type?: EmissionDetailTypeEnum | null;
      subtype?: EmissionDetailSubtypeEnum | null;
      scope?: EmissionDetailScopeEnum | null;
      category?: EmissionDetailCategoryEnum | null;
    }>;
  };
};

export const ClientProfile_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientProfile_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment, unknown>;
export const ClientUserProfile_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientUserProfile_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
  unknown
>;
export const DataCollectionRequestRequestedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DataCollectionRequestRequestedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
        },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'event' } },
            { kind: 'Field', name: { kind: 'Name', value: 'note' } },
            { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client_user' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'logo' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DataCollectionRequestRequestedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
    unknown
  >;
export const DataCollectionRequestAcceptedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DataCollectionRequestAcceptedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
        },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'event' } },
            { kind: 'Field', name: { kind: 'Name', value: 'note' } },
            { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client_user' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'logo' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DataCollectionRequestAcceptedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
    unknown
  >;
export const DataCollectionRequestDeclinedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DataCollectionRequestDeclinedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
        },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'event' } },
            { kind: 'Field', name: { kind: 'Name', value: 'note' } },
            { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client_user' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'logo' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DataCollectionRequestDeclinedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
    unknown
  >;
export const DataCollectionRequestCancelledActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DataCollectionRequestCancelledActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
        },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'event' } },
            { kind: 'Field', name: { kind: 'Name', value: 'note' } },
            { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client_user' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'logo' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DataCollectionRequestCancelledActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
    unknown
  >;
export const EmissionsModeBreakdown_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmissionsModeBreakdown_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
  unknown
>;
export const EmissionsLifecycleBreakdown_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmissionsLifecycleBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmissionsLifecycleBreakdown_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
  unknown
>;
export const EmissionsAggregations_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsAggregations' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsModeBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_lifecycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsLifecycleBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmissionsLifecycleBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmissionsAggregations_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
  unknown
>;
export const DataCollectionRequestDataStagedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DataCollectionRequestDataStagedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
        },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'event' } },
            { kind: 'Field', name: { kind: 'Name', value: 'note' } },
            { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client_user' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'emissions_aggregations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
            { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
            { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
            { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
            { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
            { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
            { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmissionsLifecycleBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
        },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
            { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
            { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
            { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
            { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
            { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
            { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'logo' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsAggregations' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
            { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
            { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
            { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
            { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
            { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
            { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'emissions_by_mode' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'EmissionsModeBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'emissions_by_lifecycle' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'EmissionsLifecycleBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
            { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DataCollectionRequestDataStagedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
    unknown
  >;
export const DataCollectionRequestSubmittedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DataCollectionRequestSubmittedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
        },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'event' } },
            { kind: 'Field', name: { kind: 'Name', value: 'note' } },
            { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client_user' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'emissions_aggregations' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
            { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
            { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
            { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
            { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
            { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
            { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'EmissionsLifecycleBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
        },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
            { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
            { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
            { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
            { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
            { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
            { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'logo' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsAggregations' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
            { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
            { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
            { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
            { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
            { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
            { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'emissions_by_mode' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'EmissionsModeBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'emissions_by_lifecycle' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'EmissionsLifecycleBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
            { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
            { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DataCollectionRequestSubmittedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
    unknown
  >;
export const DataCollectionRequestRejectedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DataCollectionRequestRejectedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
        },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'event' } },
            { kind: 'Field', name: { kind: 'Name', value: 'note' } },
            { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client_user' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'logo' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DataCollectionRequestRejectedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
    unknown
  >;
export const DataCollectionRequestApprovedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DataCollectionRequestApprovedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
        },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'event' } },
            { kind: 'Field', name: { kind: 'Name', value: 'note' } },
            { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client_user' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'logo' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DataCollectionRequestApprovedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
    unknown
  >;
export const DataCollectionRequestRemindedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DataCollectionRequestRemindedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
        },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'event' } },
            { kind: 'Field', name: { kind: 'Name', value: 'note' } },
            { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'client_user' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [
                  {
                    kind: 'FragmentSpread',
                    name: {
                      kind: 'Name',
                      value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'logo' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
      {
        kind: 'FragmentDefinition',
        name: { kind: 'Name', value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
            {
              kind: 'Field',
              name: { kind: 'Name', value: 'avatar' },
              selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DataCollectionRequestRemindedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
    unknown
  >;
export const DataCollectionRequestDeliveryFailedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DataCollectionRequestDeliveryFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'event' } },
            { kind: 'Field', name: { kind: 'Name', value: 'note' } },
            { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DataCollectionRequestDeliveryFailedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
    unknown
  >;
export const DataCollectionRequestViewedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestViewedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionRequestViewedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
  unknown
>;
export const DataCollectionRequestRequestFailedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'DataCollectionRequestRequestFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
        },
        typeCondition: {
          kind: 'NamedType',
          name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity' },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'event' } },
            { kind: 'Field', name: { kind: 'Name', value: 'note' } },
            { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    DataCollectionRequestRequestFailedActivity_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
    unknown
  >;
export const DataCollectionRequestPublic_FromGetPublicDataCollectionRequestByAuthenticationTokenFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestPublic_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestRequestedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestAcceptedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestDeclinedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestCancelledActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestDataStagedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestSubmittedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestRejectedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestApprovedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestRemindedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestDeliveryFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestViewedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestRequestFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'period_start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'period_end_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmissionsLifecycleBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsAggregations' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsModeBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_lifecycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsLifecycleBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestRequestedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestAcceptedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestDeclinedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestCancelledActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestDataStagedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestSubmittedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestRejectedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestApprovedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestRemindedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestDeliveryFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestViewedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestRequestFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DataCollectionRequestPublic_FromGetPublicDataCollectionRequestByAuthenticationTokenFragment,
  unknown
>;
export const EmissionQuality_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionQuality' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'overall' } },
          { kind: 'Field', name: { kind: 'Name', value: 'origin_destination' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_intensity_factor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmissionQuality_FromGetEmissionFragment, unknown>;
export const EmissionParentQuality_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionParentQuality' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }] },
    },
  ],
} as unknown as DocumentNode<EmissionParentQuality_FromGetEmissionFragment, unknown>;
export const EmissionIssue_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionIssue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'param' } },
          { kind: 'Field', name: { kind: 'Name', value: 'input_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assumed_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'params' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmissionIssue_FromGetEmissionFragment, unknown>;
export const Base_FreightEmission_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Base_FreightEmission_FromGetEmissionFragment, unknown>;
export const FreightEmission_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightEmission_FromGetEmissionFragment, unknown>;
export const FreightAggregatedEmission_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightAggregated' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fleet_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightAggregatedEmission_FromGetEmissionFragment, unknown>;
export const FlightsEmission_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFlights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cabin_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'airline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FlightsEmission_FromGetEmissionFragment, unknown>;
export const LastMileEmission_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionLastMile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LastMileEmission_FromGetEmissionFragment, unknown>;
export const RideHailingEmission_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionRideHailing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RideHailingEmission_FromGetEmissionFragment, unknown>;
export const EmissionDetail_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ch4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'n2o' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'so2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nmhc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmissionDetail_FromGetEmissionFragment, unknown>;
export const SegmentEmissions_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionIssue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'param' } },
          { kind: 'Field', name: { kind: 'Name', value: 'input_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assumed_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'params' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightAggregated' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fleet_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFlights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cabin_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'airline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionLastMile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionRideHailing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ch4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'n2o' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'so2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nmhc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SegmentEmissions_FromGetEmissionFragment, unknown>;
export const ChildEmissions_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionIssue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'param' } },
          { kind: 'Field', name: { kind: 'Name', value: 'input_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assumed_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'params' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightAggregated' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fleet_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFlights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cabin_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'airline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionLastMile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionRideHailing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ch4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'n2o' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'so2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nmhc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionQuality' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'overall' } },
          { kind: 'Field', name: { kind: 'Name', value: 'origin_destination' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_intensity_factor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChildEmissions_FromGetEmissionFragment, unknown>;
export const FreightEmissionShipment_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmissionShipment_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stop_info' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code_alpha_2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightEmissionShipment_FromGetEmissionFragment, unknown>;
export const Emission_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Emission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'branding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'brandColour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accentColour' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'import_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent_quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmissionShipment_fromGetEmission' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'has_parent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_child_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_share_token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionQuality' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'overall' } },
          { kind: 'Field', name: { kind: 'Name', value: 'origin_destination' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_intensity_factor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionIssue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'param' } },
          { kind: 'Field', name: { kind: 'Name', value: 'input_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assumed_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'params' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightAggregated' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fleet_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFlights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cabin_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'airline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionLastMile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionRideHailing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ch4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'n2o' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'so2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nmhc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionParentQuality' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmissionShipment_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stop_info' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code_alpha_2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Emission_FromGetEmissionFragment, unknown>;
export const OffsettingLinkFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OffsettingLinkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolios_public_ids' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'show_confirmation_page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'redirect_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OffsettingLinkFieldsFragment, unknown>;
export const PortfoliosFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfoliosFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLinkPortfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolio_version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price_per_kg' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'available_tonnes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_sold_out' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdgs' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coordinates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'coordinates' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'media' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortfoliosFieldsFragment, unknown>;
export const ClientFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offsetting_commission_percentage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'branding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'brandColour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accentColour' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientFieldsFragment, unknown>;
export const EmissionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmissionFieldsFragment, unknown>;
export const GhgTypeBreakdownItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgTypeBreakdownItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TypeBreakdownItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'biogenic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'methodology' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geocoding_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'routing_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'methodology_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GhgTypeBreakdownItemFragment, unknown>;
export const GhgBreakdownItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgBreakdownItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GhgBreakdownItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ghg' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GhgBreakdownItemFragment, unknown>;
export const GhgResultsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GhgReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total_co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2e' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_3' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ghg_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_3' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgTypeBreakdownItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TypeBreakdownItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'biogenic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'methodology' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geocoding_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'routing_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'methodology_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgBreakdownItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GhgBreakdownItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ghg' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GhgResultsFieldsFragment, unknown>;
export const GlecBreakdownFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecBreakdownFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlecBreakdownFieldsFragment, unknown>;
export const GlecReportByModeFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecReportByModeFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportByMode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'main_data_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlecReportByModeFieldsFragment, unknown>;
export const GlecResultsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_scope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_carriage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_data_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecReportByModeFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecBreakdownFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecReportByModeFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportByMode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'main_data_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlecResultsFieldsFragment, unknown>;
export const ItemisedEmissionsResultsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemisedEmissionsResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ItemisedEmissionsReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipment_leg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_shipment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipment_identifier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'from_coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'to_coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quality' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'breakdown_by_type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ItemisedEmissionsResultsFieldsFragment, unknown>;
export const ComputePublicFreightEmissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ComputePublicFreightEmission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recaptchaToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'public_compute_freight_emission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recaptcha_token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recaptchaToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Emission_fromGetEmission' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionQuality' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'overall' } },
          { kind: 'Field', name: { kind: 'Name', value: 'origin_destination' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_intensity_factor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionParentQuality' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionIssue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'param' } },
          { kind: 'Field', name: { kind: 'Name', value: 'input_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assumed_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'params' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightAggregated' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fleet_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFlights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cabin_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'airline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionLastMile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionRideHailing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ch4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'n2o' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'so2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nmhc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmissionShipment_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stop_info' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code_alpha_2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Emission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'branding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'brandColour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accentColour' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'import_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent_quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmissionShipment_fromGetEmission' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'has_parent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_child_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_share_token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ComputePublicFreightEmissionMutation, ComputePublicFreightEmissionMutationVariables>;
export const GetCalculatorUsageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCalculatorUsage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'public_calculator_usage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCalculatorUsageQuery, GetCalculatorUsageQueryVariables>;
export const AcceptPublicDataCollectionRequestByAuthenticationTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'acceptPublicDataCollectionRequestByAuthenticationToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'authentication_token' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accept_data_collection_request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'authentication_token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'authentication_token' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_authenticated' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data_collection_request' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'DataCollectionRequestPublic_fromGetPublicDataCollectionRequestByAuthenticationToken',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestRequestedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestAcceptedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestDeclinedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestCancelledActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmissionsLifecycleBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsAggregations' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsModeBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_lifecycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsLifecycleBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestDataStagedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestSubmittedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestRejectedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestApprovedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestRemindedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestDeliveryFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestViewedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestRequestFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestPublic_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestRequestedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestAcceptedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestDeclinedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestCancelledActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestDataStagedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestSubmittedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestRejectedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestApprovedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestRemindedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestDeliveryFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestViewedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestRequestFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'period_start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'period_end_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptPublicDataCollectionRequestByAuthenticationTokenMutation,
  AcceptPublicDataCollectionRequestByAuthenticationTokenMutationVariables
>;
export const DeclinePublicDataCollectionRequestByAuthenticationTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'declinePublicDataCollectionRequestByAuthenticationToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'authentication_token' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decline_data_collection_request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'authentication_token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'authentication_token' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'note' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_authenticated' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data_collection_request' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'DataCollectionRequestPublic_fromGetPublicDataCollectionRequestByAuthenticationToken',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestRequestedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestAcceptedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestDeclinedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestCancelledActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmissionsLifecycleBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsAggregations' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsModeBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_lifecycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsLifecycleBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestDataStagedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestSubmittedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestRejectedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestApprovedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestRemindedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestDeliveryFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestViewedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestRequestFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestPublic_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestRequestedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestAcceptedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestDeclinedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestCancelledActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestDataStagedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestSubmittedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestRejectedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestApprovedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestRemindedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestDeliveryFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestViewedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestRequestFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'period_start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'period_end_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeclinePublicDataCollectionRequestByAuthenticationTokenMutation,
  DeclinePublicDataCollectionRequestByAuthenticationTokenMutationVariables
>;
export const GetPublicDataCollectionRequestByAuthenticationTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPublicDataCollectionRequestByAuthenticationToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'authentication_token' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collection_request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'authentication_token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'authentication_token' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_authenticated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data_collection_request' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'DataCollectionRequestPublic_fromGetPublicDataCollectionRequestByAuthenticationToken',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestRequestedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestAcceptedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestDeclinedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestCancelledActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmissionsLifecycleBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsAggregations' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsModeBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_lifecycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsLifecycleBreakdown_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestDataStagedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestSubmittedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmissionsAggregations_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestRejectedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestApprovedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestRemindedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestDeliveryFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestViewedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestRequestFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'DataCollectionRequestPublic_fromGetPublicDataCollectionRequestByAuthenticationToken',
      },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestRequestedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestAcceptedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestDeclinedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestCancelledActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestDataStagedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestSubmittedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestRejectedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestApprovedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestRemindedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestDeliveryFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestViewedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'DataCollectionRequestRequestFailedActivity_fromGetPublicDataCollectionRequestByAuthenticationToken',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'period_start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'period_end_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ClientProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ClientUserProfile_fromGetPublicDataCollectionRequestByAuthenticationToken',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPublicDataCollectionRequestByAuthenticationTokenQuery,
  GetPublicDataCollectionRequestByAuthenticationTokenQueryVariables
>;
export const GetPublicEmissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPublicEmission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'share_token' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'public_emission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'share_token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'share_token' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Emission_fromGetEmission' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionQuality' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'overall' } },
          { kind: 'Field', name: { kind: 'Name', value: 'origin_destination' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_intensity_factor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionParentQuality' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionIssue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'param' } },
          { kind: 'Field', name: { kind: 'Name', value: 'input_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assumed_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'params' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightAggregated' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fleet_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFlights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cabin_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'airline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionLastMile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionRideHailing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ch4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'n2o' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'so2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nmhc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmissionShipment_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stop_info' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code_alpha_2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Emission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'branding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'brandColour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accentColour' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'import_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent_quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmissionShipment_fromGetEmission' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'has_parent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_child_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_share_token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPublicEmissionQuery, GetPublicEmissionQueryVariables>;
export const GetPublicOffsettingLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPublicOffsettingLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emission_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'include_emission' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'public_offsetting_link' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emission_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emission_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'include_emission' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'include_emission' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offsetting_link' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OffsettingLinkFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolios' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PortfoliosFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emission' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'include_emission' } },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OffsettingLinkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolios_public_ids' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'show_confirmation_page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'redirect_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PortfoliosFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLinkPortfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolio_version' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price_per_kg' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'available_tonnes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_sold_out' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sdgs' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coordinates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'coordinates' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'media' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offsetting_commission_percentage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'branding' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'brandColour' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accentColour' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPublicOffsettingLinkQuery, GetPublicOffsettingLinkQueryVariables>;
export const GetPublicGhgReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPublicGhgReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'share_token' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'public_ghg_report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'share_token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'share_token' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepared_for' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'import_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'client_identifier' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgResultsFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'branding' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'brandColour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accentColour' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_branded' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgTypeBreakdownItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TypeBreakdownItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'biogenic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'methodology' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geocoding_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'routing_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'methodology_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgBreakdownItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GhgBreakdownItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ghg' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GhgReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total_co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2e' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_3' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ghg_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_3' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPublicGhgReportQuery, GetPublicGhgReportQueryVariables>;
export const GetPublicGlecReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPublicGlecReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'share_token' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'public_glec_report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'share_token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'share_token' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepared_for' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version_number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'import_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'client_identifier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'supply_chain_coverage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'confirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'confirmed_text' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecResultsFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'branding' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'brandColour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accentColour' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_branded' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecBreakdownFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecReportByModeFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportByMode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'main_data_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_scope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_carriage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_data_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecReportByModeFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPublicGlecReportQuery, GetPublicGlecReportQueryVariables>;
export const GetPublicItemisedEmissionsReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPublicItemisedEmissionsReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'share_token' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'public_itemised_emissions_report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'share_token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'share_token' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepared_for' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version_number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'import_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'client_identifier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipment_identifier' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemisedEmissionsResultsFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'branding' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'brandColour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accentColour' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'is_branded' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemisedEmissionsResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ItemisedEmissionsReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipment_leg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_shipment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipment_identifier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'from_coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'to_coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quality' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'breakdown_by_type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPublicItemisedEmissionsReportQuery, GetPublicItemisedEmissionsReportQueryVariables>;
export const GetPublicReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPublicReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'share_token' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'public_report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'share_token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'share_token' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPublicReportQuery, GetPublicReportQueryVariables>;
export const GetPublicUntrackedEmissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPublicUntrackedEmission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'public_untracked_emission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Emission_fromGetEmission' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionQuality' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'overall' } },
          { kind: 'Field', name: { kind: 'Name', value: 'origin_destination' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_intensity_factor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionParentQuality' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionIssue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'param' } },
          { kind: 'Field', name: { kind: 'Name', value: 'input_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assumed_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'params' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightAggregated' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fleet_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFlights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cabin_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'airline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionLastMile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionRideHailing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ch4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'n2o' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'so2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nmhc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmissionShipment_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stop_info' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code_alpha_2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Emission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'branding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'brandColour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accentColour' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'import_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent_quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmissionShipment_fromGetEmission' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'has_parent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_child_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_share_token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPublicUntrackedEmissionQuery, GetPublicUntrackedEmissionQueryVariables>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ComputeFreightEmissions: [
      'ComputeFreightEmissionsWithDistanceAndWeightAndVehicle',
      'ComputeFreightEmissionsWithLocationsAndWeightAndVehicle',
    ],
    DataCollectionRequestActivity: [
      'DataCollectionRequestAcceptedActivity',
      'DataCollectionRequestApprovedActivity',
      'DataCollectionRequestCancelledActivity',
      'DataCollectionRequestDataStagedActivity',
      'DataCollectionRequestDeclinedActivity',
      'DataCollectionRequestDeliveryFailedActivity',
      'DataCollectionRequestRejectedActivity',
      'DataCollectionRequestRemindedActivity',
      'DataCollectionRequestRequestFailedActivity',
      'DataCollectionRequestRequestedActivity',
      'DataCollectionRequestSubmittedActivity',
      'DataCollectionRequestViewedActivity',
    ],
    FreightRoute: ['FreightRouteStops', 'FreightRouteVia'],
    LocationFreight: ['LocationAddress', 'LocationCode', 'LocationCoordinates'],
    ViaLocation: ['LocationCode', 'LocationCoordinates'],
  },
};
export default result;
